

.bundleSoldOutSeat {
  display: flex;
  /* flex: 0 0 14.28571%; */
  padding: 5px;
  gap: 10px;
  /* width:50px; */
  /* height: max-content; */
  position: relative;
}
/* .seat:nth-child(3) {
  margin-right: 14.28571%;
} */
.bundleSoldOutSeat input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.bundleSoldOutSeat input[type="checkbox"]:disabled + label {
  background: #ddd !important;
  color: white !important;
  overflow: hidden !important;
  border: 3px solid #bada55 !important;
}
.bundleSoldOutSeat input[type="checkbox"]:disabled + label:after {
  /* content: ""; */
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.bundleSoldOutSeat input[type="checkbox"]:disabled + label:after {
  /* content: ""; */
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.bundleSoldOutSeat input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.bundleSoldOutSeat label {
  display: block;
  position: relative;
  width: 100% !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1rem;
  padding: 2px;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
  border: 3px solid #bada55 !important;
}
.bundleSoldOutSeat label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}

/*  */

.selectBundleSeat {
  display: flex;
  /* flex: 0 0 14.28571%; */
  padding: 5px;
  gap: 10px;
  /* width:50px; */
  /* height: max-content; */
  position: relative;
}
.selectBundleSeat input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
.selectBundleSeat input[type="checkbox"]:checked + label {
  background: #bada55 !important;
  color: #ffffff !important;
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.selectBundleSeat input[type="checkbox"]:disabled + label {
  background: #ddd !important;
  color: white !important;
  overflow: hidden !important;
  border: 3px solid #dddddd !important;
}
.selectBundleSeat input[type="checkbox"]:disabled + label:after {
  /* content: ""; */
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.selectBundleSeat input[type="checkbox"]:disabled + label:after {
  /* content: ""; */
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.selectBundleSeat input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.selectBundleSeat label {
  display: block;
  position: relative;
  width: 100% !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1rem;
  padding: 2px;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
  border: 3px solid #bada55 !important;
}
.selectBundleSeat label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}
