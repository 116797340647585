article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  appearance: auto !important;
}
::-moz-selection {
  /* Code for Firefox */
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87);
}

::selection {
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87);
}

ol,
ul {
  list-style: none;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
a:hover {
  color: #3c52e9 !important;
  text-decoration: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
button {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  margin: 0;
  padding: 0;
}
a {
  color: #5065f6 !important;
  text-decoration: none !important;
}
a:focus {
  text-decoration: none;
  outline: none;
}
a:hover {
  color: #3c52e9 !important;
  text-decoration: none !important;
}
button {
  outline: none;
}

button:focus {
  outline: none !important;
}

input {
  outline: none;
  border-radius: 3px;
}

textarea {
  outline: none;
  border-radius: 3px;
}

p {
  font-size: 14px;
  line-height: 26px;
  color: #717171;
  letter-spacing: 0.1px;
}

/* ================ Typography Css =============== */

/* ================ CROSS BROWSER COMPATABILITY =============== */

nav,
nav ul li a,
nav ul li:hover > ul {
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

/* === Body Scrollbar === */

body::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
body::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
body::-webkit-scrollbar-corner {
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  font-size: 0.875rem;
  line-height: 1.42857143;
  color: #333;
  background: #f5f7f9 !important;
}

/****************
Night Mood
****************/

.night-mode #night-mode {
  padding: 0;
  font-size: 15px;
  background: #1d3f73;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

/* .night-mode .fa-sun:before {
    content: "\f186";
} */

.night-mode html,
.night-mode body {
  background-color: #111315 !important;
  color: #6f767e !important;
}

.night-mode body {
  background: #111315 !important;
  height: auto;
  float: left;
  width: 100%;
}

.night-mode body::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

.night-mode body::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.night-mode body::-webkit-scrollbar-corner {
  background-color: transparent;
}

.night-mode body::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(51, 51, 51, 0.6);
  -webkit-box-shadow: inset -1px -1px 0 rgba(51, 51, 51, 0.05),
    inset 1px 1px 0 rgba(51, 51, 51, 0.05);
}

.night-mode h1,
.night-mode h2,
.night-mode h3,
.night-mode h4,
.night-mode h5 {
  color: #fff !important;
}

.night-mode p {
  color: #6f767e;
}

.night-mode #logo .logo-inverse {
  display: inline !important;
}

.night-mode #logo img {
  display: none;
}

.night-mode .bg-barren {
  background: #1a1d1f;
  box-shadow: inset 1px 0px 0px #111315, inset 0 -1px 0px #111315;
}

.night-mode .nav-link {
  color: #6f767e !important;
  font-weight: 500;
}

.night-mode .nav-link:hover {
  color: #fcfcfc !important;
}

.night-mode .barren-head .nav-item .nav-link.active,
.night-mode .barren-head .nav-item .show > .nav-link {
  color: #fff !important;
}

.night-mode .barren-breadcrumb .breadcrumb .breadcrumb-item a {
  color: #9a9fa5;
}

.night-mode .barren-breadcrumb .breadcrumb .breadcrumb-item a:hover {
  color: #fcfcfc !important;
}

.night-mode .barren-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #9a9fa5 !important;
}

.night-mode .footer {
  background: #1a1d1f;
  color: #fff;
}

.night-mode .footer-link {
  color: #6f767e;
}

.night-mode .footer-link:hover {
  color: #fcfcfc !important;
}

.night-mode .filter-tag a {
  color: #6f767e;
  border: 1px solid #33383f;
  background: #272b30;
}

.night-mode .filter-tag a.active,
.night-mode .filter-tag a:hover {
  background: #272b30;
  color: #fff !important;
}

.night-mode .main-card {
  border: 1px solid #1a1d1f;
  background-color: #1a1d1f;
}

.night-mode .event-content > .event-title {
  color: #fff;
}

.night-mode .event-content > .event-title:hover {
  color: #fff !important;
}

.night-mode .duration-price {
  color: #fff;
}

.night-mode .remaining {
  color: #9a9fa5;
}

.night-mode .control {
  color: #6f767e;
  background: #272b30;
}

.night-mode .mixitup-control-active {
  color: #fff !important;
}

.night-mode .mixitup-control-active[data-filter]:after {
  background: transparent;
  border-bottom: 3px solid #fff;
  color: #fff;
}

.night-mode .event-timing {
  color: #9a9fa5;
}

.night-mode .host-engaging-event-block {
  background: #272b30;
}

.night-mode .owl-nav .owl-next {
  color: #6f767e !important;
  background: #383c41 !important;
  border: 1px solid #383c41 !important;
}

.night-mode .owl-nav .owl-prev {
  color: #6f767e !important;
  background: #383c41 !important;
  border: 1px solid #383c41 !important;
}

.night-mode .owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #1d3f73 !important;
  border: 1px solid #1d3f73 !important;
}

.night-mode .owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #1d3f73 !important;
  background: #1d3f73 !important;
}

.night-mode .feature-group-list {
  border-top: 2px solid #272b30;
}

.night-mode .host-step-block {
  background-color: #272b30;
}

.night-mode .step-text {
  color: #fff;
}

.night-mode .step-link span {
  color: #fff;
}

.night-mode .step-tabs .step-link:hover {
  background-color: #1a1d1f;
}

.night-mode .step-link {
  border-bottom: 1px solid #1a1d1f;
  color: #9a9fa5;
}

.night-mode .step-tabs .step-link.active:hover {
  color: #fff;
  background-color: #1d3f73;
}

.night-mode .testimonial-slider-area {
  border-top: 2px solid #272b30;
}

.night-mode .testimonial-user-dt span {
  color: #9a9fa5;
}

.night-mode .dropdown-menu {
  background-color: #111315 !important;
  border: 1px solid #272b30 !important;
}

.night-mode .dropdown-submenu .dropdown-item {
  color: #6f767e;
}

.night-mode .dropdown-item {
  color: #6f767e !important;
}

.night-mode .dropdown-submenu .dropdown-item:hover {
  color: #fff !important;
  background-color: transparent !important;
}

.night-mode .dropdown-submenu .dropdown-item:focus {
  color: #fff !important;
  background-color: transparent;
}

.night-mode .dropdown-menu-account li .link-item {
  color: #6f767e !important;
}

.night-mode .dropdown-menu-account li .link-item:hover {
  color: #fff !important;
  background-color: transparent !important;
}

.night-mode .profile-link {
  border-top: 1px solid #272b30;
}

.night-mode .account-link {
  color: #6f767e;
}

.night-mode .account-link:hover {
  color: #fcfcfc !important;
}

.night-mode .navbar-toggler-icon {
  color: #6f767e;
}

.night-mode .navbar-toggler-icon:hover {
  color: #fcfcfc;
}

.night-mode .offcanvas {
  background-color: #1a1d1f;
}

.night-mode .close-btn {
  background: #272b30;
  color: #6f767e;
}

.night-mode .close-btn:hover,
.night-mode .close-btn:focus {
  background: #272b30;
  color: #fcfcfc;
}

.night-mode .checkout-title {
  border-bottom: 2px solid #272b30;
}

.night-mode .custom-border-top {
  border-top: 2px solid #272b30;
}

.night-mode .team-content span {
  color: #6f767e;
}

.night-mode .user-dts .user-name {
  color: #fff;
}

.night-mode .esv-btn {
  color: #fcfcfc;
  border: 1px solid #272b30;
  background: #272b30;
}

.night-mode .esv-btn:hover {
  color: #fff;
  border: 1px solid #efefef;
}

.night-mode .co-main-btn {
  color: #fcfcfc;
  border: 1px solid #272b30 !important;
  background: #272b30 !important;
}

.night-mode .co-main-btn:hover {
  color: #fff !important;
  border: 1px solid #efefef !important;
  background: #272b30 !important;
}

.night-mode .ff-block a {
  color: #fcfcfc;
}

.night-mode .ff-block a:first-child {
  border-right: 1px solid #272b30;
}

.night-mode .profile-social-link h6 {
  color: #fff;
}

.night-mode .modal-content {
  background-color: #111315;
  border: 1px solid #272b30;
}

.night-mode .close-model-btn {
  background: #272b30;
  color: #6f767e;
  opacity: 1;
}

.night-mode .close-model-btn:hover {
  background: #272b30;
  color: #fcfcfc;
}

.night-mode .ff-tablist {
  border-bottom: 1px solid #272b30;
}

.night-mode .follow-btn {
  color: #fcfcfc;
  border: 1px solid #272b30;
  background: #272b30 !important;
}

.night-mode .follow-btn:hover {
  color: #fcfcfc !important;
  border: 1px solid #efefef !important;
}

.night-mode .modal-header {
  border-bottom: 1px solid #272b30 !important;
}

.night-mode .modal-footer {
  border-top: 1px solid #272b30 !important;
}

.night-mode .text-light3 {
  color: #6f767e !important;
}

.night-mode .form-label {
  color: #efefef;
}

.night-mode .model-content .form-control {
  background-color: #272b30 !important;
}

.night-mode .form-control {
  color: #000000 !important;
  background-color: #272b30 !important;
  border: 1px solid #272b30 !important;
}

.night-mode .form-control:focus {
  color: #fcfcfc !important;
  background-color: #000 !important;
  border: 1px solid #1d3f73 !important;
}

.night-mode .form-textarea {
  color: #fcfcfc !important;
  background-color: #272b30 !important;
  border: 1px solid #272b30 !important;
}

.night-mode .form-textarea:focus {
  color: #fcfcfc !important;
  background-color: #000 !important;
  border: 1px solid #1d3f73 !important;
}

.night-mode .border_bottom {
  border-bottom: 1px solid #272b30;
}

.night-mode .border_top {
  border-top: 1px solid #272b30;
}

.night-mode .blog-title {
  color: #fcfcfc;
}

.night-mode .blog-title:hover {
  color: #fcfcfc !important;
}

.night-mode .cb-icon {
  color: #9a9fa5;
  background: #272b30;
}

.night-mode .cb-icon:hover {
  color: #fff !important;
  background: #1d3f73;
}

.night-mode .booking-confirmed-bottom-bg {
  background: #272b30;
}

.night-mode .buyer-name {
  color: #9a9fa5;
}

.night-mode .booking-total-tickets span {
  color: #9a9fa5;
}

.night-mode .booking-total-grand span {
  color: #9a9fa5;
}

.night-mode .booking-total-grand {
  color: #9a9fa5;
}

.night-mode .bp-title h4 {
  border-bottom: 1px solid #272b30;
}

.night-mode .divider-line {
  background: #272b30;
}

.night-mode .order-text {
  color: #efefef;
}

.night-mode .confirmation-btn span {
  color: #fcfcfc;
}

.night-mode .event-order-dt-content .category-type {
  color: #efefef;
}

.night-mode .bp-form .btn-light {
  color: #fcfcfc !important;
  background-color: #272b30 !important;
  border: 1px solid #272b30 !important;
}

.night-mode .bootstrap-select .dropdown-item:hover {
  color: #fcfcfc !important;
  background: inherit !important;
}

.night-mode .bootstrap-select .dropdown-item.active:hover {
  color: #fcfcfc !important;
  background: #1d3f73 !important;
}

.night-mode .bootstrap-select.dropdown-item.active,
.night-mode .bootstrap-select .dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #1d3f73 !important;
}

.night-mode .main-form .bootstrap-select .dropdown-toggle:focus,
.night-mode
  .main-form
  .bootstrap-select
  > select.mobile-device:focus
  + .dropdown-toggle {
  outline: none !important;
  outline: none !important;
  outline-offset: -2px;
  border-color: #a2ff7a !important;
  box-shadow: none !important;
}

.night-mode .ttl-clr {
  color: #fff;
}

.night-mode .account-link img {
  background-color: #272b30;
  border: 2px solid #272b30;
}

.night-mode .user-avatar-img img {
  background: #272b30;
  border: 2px solid #1d3f73;
}

.night-mode .account-holder-avatar img {
  background-color: #272b30;
  border: 2px solid #1d3f73;
}

.night-mode .account-link.show img {
  border: 2px solid #1d3f73;
}

.night-mode .coming-soon-content {
  background-color: #1a1d1f;
}

.night-mode .copyright-footer {
  color: #efefef;
}

.night-mode .contact-hero-banner-info p {
  color: #fff !important;
}

.night-mode .create-icon i {
  color: #9a9fa5;
  background: #272b30;
}

.night-mode #add-event-tab .step-steps > li:before {
  background-color: #272b30;
}

.night-mode #add-event-tab .step-steps > li > a > .number {
  background-color: #272b30;
}

.night-mode #add-event-tab .step-steps > li > a > .number:before {
  background-color: rgba(39, 43, 48, 0.3);
}

.night-mode #add-event-tab .step-steps > li.active > a > .number,
.night-mode #add-event-tab .step-steps > li.done > a > .number {
  background-color: #1d3f73;
}

.night-mode #add-event-tab .step-steps > li.active > a > .number:before,
.night-mode #add-event-tab .step-steps > li.done > a > .number:before {
  background-color: rgba(106, 192, 69, 0.3);
}

.night-mode #add-event-tab .step-steps > li.done:before {
  background-color: #1d3f73;
}

.night-mode #add-event-tab .step-steps > li.active > a > .step-name,
.night-mode #add-eventevent-tab .step-steps > li.done > a > .step-name {
  color: #fff;
}

.night-mode .color-black {
  color: #fff;
}

.night-mode .hosting-title {
  color: #fcfcfc;
}

.night-mode .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #272b30 !important;
}

.night-mode .ck.ck-editor__main > .ck-editor__editable:focus {
  border-color: #272b30 !important;
  background: #000;
}

.night-mode .ck.ck-editor__main > .ck-editor__editable {
  background: #272b30;
  border-radius: 0;
}

.night-mode .ck.ck-editor__main > .ck-editor__editable p {
  color: #fcfcfc;
}

.night-mode .btn-switch .checkbox-slider {
  background-color: #272b30;
}

.night-mode .datepicker {
  background: #111315;
  border: 1px solid #272b30;
  color: #6f767e;
}

.night-mode .datepicker--nav {
  border-bottom: 1px solid #272b30;
}

.night-mode .datepicker--nav-title:hover {
  background: #272b30;
}

.night-mode .datepicker--pointer {
  background: #111315;
  border-top: 1px solid #272b30;
  border-right: 1px solid #272b30;
}

.night-mode .absolute-icon {
  color: #fcfcfc;
}

.night-mode .brn-checkbox-radio span {
  color: #fcfcfc;
}

.night-mode .brn-checkbox-radio span:last-child {
  color: #6f767e;
}

.night-mode .percentage-icon {
  color: #fcfcfc;
}

.night-mode .price-ticket-card .price-ticket-card-body h6 {
  color: #fcfcfc;
}

.night-mode .price-ticket-card .price-ticket-card-body .icon-box .icon {
  color: #9a9fa5;
  background: #272b30;
}

.night-mode .price-ticket-card .price-ticket-card-body .text-145 {
  color: #9a9fa5;
}

.night-mode .dropdown-item:hover {
  color: #fff !important;
  background-color: transparent !important;
}

.night-mode .option-btn-1 {
  color: #fcfcfc;
  border: 1px solid #272b30;
  background: #272b30;
}

.night-mode .option-btn-1:hover {
  color: #fcfcfc;
  border: 1px solid #efefef;
}

.night-mode .price-variation-head {
  background: #272b30;
}

.night-mode .model-content .btn-light {
  color: #fcfcfc !important;
  background-color: #272b30 !important;
  border: 1px solid #272b30 !important;
}

.night-mode .div-base-table .table-row .table-col {
  -webkit-box-shadow: 0 1px 0 #272b30;
  -moz-box-shadow: 0 1px 0 #272b30;
  box-shadow: 0 1px 0 #272b30;
}

.night-mode .price-ticket-card {
  border: 1px solid #272b30;
}

.night-mode .search-form .btn-light {
  color: #fcfcfc !important;
  background-color: #111315 !important;
  border: 1px solid #111315 !important;
}

.night-mode .faq-widget-links li a {
  color: #fcfcfc;
}

.night-mode .accordion-btn:not(.collapsed) {
  color: #fff;
}

.night-mode .accordion-btn {
  color: #fff;
}

.night-mode .faq-accordion .accordion-item:not(:first-of-type) {
  border-top: 1px solid #272b30;
}

.night-mode .faq-accordion .accordion-item {
  border-top: 1px solid #272b30;
}

.night-mode .faq-accordion .accordion-item:last-child {
  border-bottom: 1px solid #272b30;
}

.night-mode .app-form-content {
  background: #111315;
}

.night-mode .help-item-icon {
  background: #272b30;
  color: #fcfcfc;
}

.night-mode .help-item-link {
  color: #fcfcfc;
  border-bottom: 1px solid #272b30;
}

.night-mode .help-item-link:last-child {
  border-bottom: 0;
}

.night-mode .hero-banner-content .form-control {
  background-color: #111315 !important;
  border: 1px solid #111315;
}

.night-mode .invoice-body {
  background: #272b30;
}

.night-mode .vdt-list {
  color: #bbb;
}

.night-mode .cut-line i {
  background: #272b30;
}

.night-mode .invoice-header {
  background: #1a1d1f;
}

.night-mode .QR-dt {
  background: #2a2d2f;
}

.night-mode .QR-counter-type li {
  color: #fcfcfc;
}

.night-mode .main-table .table .thead-dark th {
  color: #efefef;
  background-color: #272b30;
  border-color: #272b30;
}

.night-mode .main-table .table {
  color: #fff;
  background: #1a1d1f;
  border: 1px solid #272b30;
}

.night-mode .main-table .table tbody tr td a {
  color: #fff;
}

.night-mode .main-table .table tbody tr td a:hover {
  color: #fff !important;
}

.night-mode .totalinv2 {
  color: #fcfcfc;
}

.night-mode .ocard-right .co-main-btn i {
  background: #272b30;
  border: 2px dashed #efefef;
  color: #efefef;
}

.night-mode .org_design_button {
  border: 2px dashed #272b30;
  background: #111315;
}

.night-mode .address-title {
  border-bottom: 1px solid #272b30;
  color: #fff;
}

.night-mode .ct-label {
  color: #c9c9c9;
}

.night-mode .ct-grid {
  stroke: rgba(255, 255, 255, 0.2) !important;
}

.night-mode .btn-light {
  color: #fcfcfc !important;
  background-color: #272b30 !important;
  border: 1px solid #272b30 !important;
}

.night-mode .menu--link.active {
  background: #272b30;
  color: #fff;
}

.night-mode .vertical_nav {
  background: #1a1d1f;
}

.night-mode .menu--link {
  color: #6f767e !important;
  font-weight: 500;
}

.night-mode .menu--link:hover,
.night-mode .menu--link:hover .menu--label {
  color: #fcfcfc;
  background: transparent;
}

.night-mode .sub_menu--link .menu--icon:hover {
  color: #fff !important;
}

.night-mode .custom2-tabs .tab-link {
  background: #272b30;
  border: 1px solid #272b30;
  color: #fcfcfc;
}

.night-mode .custom2-tabs .tab-link {
  background: #272b30;
  border: 1px solid #272b30;
  color: #fcfcfc;
}

.night-mode .custom2-tabs .tab-link:hover {
  color: #fcfcfc;
  background-color: #272b30;
  border: 1px solid #efefef;
}

.night-mode .custom2-tabs .tab-link.active,
.night-mode .tab-tabs .show > .tab-link {
  color: #fff;
  background-color: #1d3f73;
  border: 1px solid #1d3f73;
}

.night-mode .custom2-tabs .tab-link.active span,
.night-mode .tab-tabs .show > .tab-link span {
  color: #fff;
}

.night-mode .card-top {
  border-bottom: 1px solid #272b30;
}

.night-mode .card-dt-text h6 {
  color: #9a9fa5;
}

.night-mode .card-dt-text span {
  color: #fcfcfc;
}

.night-mode .card-icon i {
  color: #9a9fa5;
  background: #272b30;
}

.night-mode .option-btn {
  color: #fcfcfc;
  border: 1px solid #272b30;
  background: #272b30;
}

.night-mode .option-btn:hover {
  border: 1px solid #efefef;
}

.night-mode .event-list-icon {
  background: #272b30;
  border: 1px solid #272b30;
}

.night-mode .contact-list .bottom .icon-box .icon {
  color: #9a9fa5;
  background: #272b30;
}

.night-mode .contact-list .bottom .icon-box p {
  color: #9a9fa5;
}

.night-mode .contact-list .bottom .icon-box h6 {
  color: #fff;
}

.night-mode .gift-coupon-icon {
  border-bottom: 2px dashed #272b30;
}

.night-mode .contact-list .top .icon-box h5 {
  color: #fff;
}

.night-mode .contact-list .top .icon-box p {
  color: #6f767e;
}

.night-mode .bank-card {
  border: 1px solid #272b30;
  background: #272b30;
}

.night-mode .bank-card h6 {
  color: #efefef;
}

.night-mode .action-link {
  color: #fcfcfc;
  border: 1px solid #111315;
  background: #111315;
}

.night-mode .action-link:hover {
  color: #fcfcfc !important;
  border: 1px solid #efefef;
}

.night-mode .subscription-item {
  border: 1px solid #272b30;
}

.night-mode .s-info p {
  color: #fff;
}

.night-mode .tracking-tab .tracking-head {
  background: #272b30;
  border: 1px solid #272b30;
}

.night-mode .tracking-tab .nav-tabs .nav-link {
  background: #272b30;
}

.night-mode .tracking-tab .nav-tabs .nav-link.active {
  background-color: #1d3f73;
  color: #fff !important;
}

.night-mode .specific-event {
  border-top: 1px solid #272b30;
}

.night-mode .view-profile-link {
  border-top: 1px solid #272b30;
}

.night-mode .p-bg {
  background-color: #000;
}

.night-mode .copy-link {
  background: #111315;
  border: 1px solid #111315;
}

.night-mode .upgrade-content .upgrade-premium-center {
  background-color: #111315;
}

.night-mode .event-dt-right-icon i {
  color: #9a9fa5;
  background: #272b30;
}

.night-mode .sv-btn {
  color: #fcfcfc;
  border: 1px solid #272b30;
  background: #272b30;
}

.night-mode .sv-btn:hover {
  color: #fcfcfc;
  border: 1px solid #efefef;
  background: #272b30;
}

.night-mode .event-type-name {
  color: #9a9fa5;
}

.night-mode .details-hr:after {
  background: #9a9fa5;
}

.night-mode .more-events {
  border-top: 1px solid #272b30;
}

.night-mode .garren-line-tab {
  border: 1px solid #1a1d1f;
  background-color: #1a1d1f;
}

.night-mode .my-event-tabs {
  background: #1a1d1f;
  border: 1px solid #1a1d1f;
}

.night-mode .my-event-tabs .event-link.active:hover {
  color: #1d3f73;
  background-color: #1a1d1f;
  border-bottom: 3px solid #1d3f73;
}

.night-mode .my-event-tabs .event-link.active,
.night-mode .my-event-tabs .show > .event-link {
  color: #1d3f73;
  background-color: #1a1d1f;
  border-bottom: 3px solid #1d3f73;
}

.night-mode .event-link span {
  color: #fcfcfc;
}

.night-mode .event-count {
  background: #272b30;
  color: #9a9fa5;
}

.night-mode .card-top:last-child {
  border-bottom: 0;
}

.night-mode .about-step {
  border-bottom: 1px solid #272b30;
}

.night-mode .about-step:last-child {
  border-bottom: 0;
}

.night-mode .setting-step {
  border-bottom: 1px solid #272b30;
}

.night-mode .setting-step:last-child {
  border-bottom: 0;
}

.night-mode .tab-link {
  color: #6f767e;
}

.night-mode .custom-tabs .tab-link.active,
.tab-tabs .show > .tab-link {
  color: #1d3f73;
  background-color: #1a1d1f;
  border-bottom: 3px solid #1d3f73;
}

.night-mode .custom-tabs .tab-link:hover {
  color: #fff;
  background-color: #1a1d1f;
  border-bottom: 3px solid transparent;
}

.night-mode .custom-tabs .tab-link.active span,
.night-mode .tab-tabs .show > .tab-link span {
  color: #fff;
}

.night-mode .custom-tabs .tab-link.active:hover {
  color: #1d3f73;
  background-color: #1a1d1f;
  border-bottom: 3px solid #1d3f73;
}

.night-mode .flex-slide .right h3 {
  color: #fff;
}

.night-mode .calculate-top {
  background: #1a1d1f;
}

.night-mode .bg-white {
  background: #212529 !important;
}

.night-mode .app-top-right-link {
  color: #fff;
}

.night-mode .divider span {
  background: #111315;
  color: #fff;
}

.night-mode .divider:before {
  background: rgba(112, 112, 112, 0.4);
}

.night-mode .social-login-btn {
  color: #fcfcfc;
  border: 1px solid #272b30;
  background: #272b30;
}

.night-mode .social-login-btn:hover {
  color: #fcfcfc;
  border: 1px solid #efefef;
  background: #272b30;
}

.night-mode .agree-text {
  color: #efefef;
}

.night-mode .term-link {
  color: #fcfcfc;
}

.night-mode .terms-content ol li {
  color: #fcfcfc;
}

.night-mode .select-tickets-block h6 {
  color: #fff;
  border-bottom: 1px solid #272b30;
}

.night-mode .ticket-price {
  color: #fff;
}

.night-mode .x-title {
  color: #6f767e;
}

.night-mode .counter input {
  color: #fcfcfc;
}

.night-mode .counter span {
  color: #efefef;
  background: #111315;
  border: 2px solid #272b30;
}

.night-mode .counter span:hover {
  color: #fff;
  background: #1d3f73;
  border: 2px solid #1d3f73;
}

.night-mode .xtotel-tickets-count {
  border-top: 1px solid #272b30;
}

.night-mode .help-faq-link {
  color: #fcfcfc;
}

.night-mode .help-pagination-link {
  color: #fcfcfc;
}

.night-mode .premium-select .option {
  border: 2px solid #272b30;
  background-color: #1a1d1f;
}

.night-mode .premium-select .option .dot {
  background: #aaaaaa;
}

.night-mode .wrapper .option span {
  color: #fcfcfc;
}

.night-mode .wrapper .option span:last-child {
  color: #a2a2a2;
}

.night-mode #option-1:checked:checked ~ .option-1,
.night-mode #option-2:checked:checked ~ .option-2 {
  border: 2px solid #1d3f73;
  background: #1a1d1f;
}

.night-mode #option-1:checked:checked ~ .option-1 span,
.night-mode #option-2:checked:checked ~ .option-2 span {
  color: #fff;
}

.night-mode #option-1:checked:checked ~ .option-1 span:last-child,
.night-mode #option-2:checked:checked ~ .option-2 span:last-child {
  color: #a2a2a2;
}

.night-mode .upload-file-dt {
  background: #111315;
  border: 2px dashed #272b30;
}

.night-mode .upload-file-dt span {
  color: #fff;
}

.night-mode .featured-controls label span {
  color: #6f767e;
  border: 1px solid #33383f;
  background: #272b30;
}

.night-mode .featured-controls input:checked + span {
  background: #272b30;
  color: #fff !important;
}

.night-mode .role-header {
  background: #272b30;
}

.night-mode .role-header h6 {
  color: #fff;
}

.night-mode .role-header span {
  color: #a2a2a2;
}

.night-mode .role-item span {
  color: #fff;
}

.night-mode .i-disabled {
  color: #fff !important;
  background: rgba(255, 255, 255, 0.1) !important;
}

.night-mode {
}

.night-mode {
}

.night-mode {
}

.night-mode {
  background-color: #111315 !important;
  color: #6f767e !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .night-mode .create-bg {
    background-color: #272b30;
  }
  .card-event-img-report img {
    margin-left: -5px !important;
    height: 100% !important;
  }
  .card-event-img-report-responsive {
    height: 100% !important;
    margin-left: 25% !important;
  }
  .card-event-img-responsive {
    height: 100% !important;
  }
  .night-mode .offcanvas-header {
    border: 1px solid #272b30;
  }

  .night-mode .navbar-nav .nav-link:hover {
    background-color: #1a1d1f;
  }

  .night-mode .dropdown-menu {
    background-color: #111315 !important;
    border: 1px solid transparent !important;
  }

  .night-mode .offcanvas-footer {
    border-top: 1px solid #272b30;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .night-mode .create-bg {
    background-color: #272b30;
  }

  .night-mode .offcanvas-header {
    border: 1px solid #272b30;
  }

  .night-mode .navbar-nav .nav-link:hover {
    background-color: #1a1d1f;
  }

  .night-mode .dropdown-menu {
    background-color: #111315 !important;
    border: 1px solid transparent !important;
  }

  .night-mode .offcanvas-footer {
    border-top: 1px solid #272b30;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .night-mode .create-bg {
    background-color: #272b30;
  }
  .card-event-img-report img {
    width: 100% !important;
  }
  .night-mode .offcanvas-header {
    border: 1px solid #272b30;
  }

  .night-mode .navbar-nav .nav-link:hover {
    background-color: #1a1d1f;
  }

  .night-mode .dropdown-menu {
    background-color: #111315 !important;
    border: 1px solid transparent !important;
  }

  .night-mode .offcanvas-footer {
    border-top: 1px solid #272b30;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/****************
Responsive CSS
****************/

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .res-main-logo {
    display: inline-block;
  }
  .dateInputSty {
    width: 130% !important;
  }
  .res-main-logo img {
    /* height: 49px; */
    width: 71px;
  }
  .modal-edit-popup-responsive {
    width: 75% !important;
    margin-left: 13% !important;
  }
  .owl-nav .owl-prev {
    top: 13%;
  }
  .owl-nav .owl-next {
    top: 13%;
  }
  .owl-item {
    width: 300px !important;
  }
  .owl-stage {
    width: 5000px !important;
  }

  .card-event-img img {
    height: 50% !important;
    width: 100% !important;
  }
  .modal-myteam {
    width: 86% !important;
    background-color: #fff;
    height: 47% !important;
    margin-top: 6%;
    margin-left: 7% !important;
    display: block;
    border-radius: 7px;
  }
  .selectpicker-add-orgReport {
    position: absolute !important;
    display: block !important;
    height: 10% !important;
    width: 50% !important;
    -ms-flex-pack: justify;
    background: transparent;
    border-radius: 3px;
    padding-left: 14px;
    border: 1px solid #efefef;
    font-size: 14px;
  }
  .resell-card {
    width: 100% !important;
  }
  .main-logo {
    display: none;
  }

  .create-btn {
    padding: 5px 10px;
    display: none;
  }

  .create-btn span {
    display: none;
  }

  .create-btn i {
    margin-right: 0;
  }

  .navbar-nav .nav-link {
    padding: 12px 25px 12px 20px !important;
    font-size: 1.1em;
  }
  .lang-select-box {
    margin: 12px 25px 12px 20px !important;
  }

  .navbar-nav .nav-link:hover {
    color: #1d3f73 !important;
    background-color: #e8f7f7;
  }

  .offcanvas-header {
    border: 1px solid #efefef;
  }

  .offcanvas-logo img {
    width: 71px;
    height: 47px;
  }

  .offcanvas-body {
    padding: 0 0 1rem !important;
  }

  .pe_5 {
    padding-right: 0 !important;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    border: 0 !important;
    box-shadow: none !important;
    padding: 0 0 0 20px !important;
  }

  .navbar-nav .dropdown-menu .dropdown-item {
    font-size: 18px;
    padding: 0.6rem 1rem !important;
    color: #000;
  }

  .dropdown-submenu .dropdown-item:hover {
    color: #1d3f73 !important;
    background-color: transparent !important;
  }

  .navbar-nav .dropdown-menu .dropdown-item::before {
    /* content:'\f068'; */
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
  }

  .navbar-nav .dropdown-toggle::after {
    display: inline-block;
    float: right;
    margin-top: 10px;
  }

  .submenu-item:after {
    content: "\f0dd";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    float: right;
    font-size: 12px;
    padding-right: 10px;
  }

  .offcanvas-top-area {
    display: block;
    padding: 0 0 20px;
    text-align: center;
  }

  .create-bg {
    background-color: #f1f2f3;
    border-radius: 0;
    padding: 30px;
  }

  .dropdown-submenu {
    width: 100%;
  }

  .hero-banner-content h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .hero-banner-content p {
    padding: 0;
  }

  .easy-steps-tab .nav {
    display: block;
    width: 100%;
  }

  .step-link {
    width: 100%;
  }

  .main-title h3 {
    font-size: 24px;
    line-height: 42px;
  }

  .p-80 {
    padding: 64px 0 64px;
  }

  .app-form-sidebar {
    display: none;
  }

  .sign-logo {
    display: block !important;
  }

  .app-top-right-link {
    display: none;
  }

  .app-top-items {
    display: block;
    width: 100%;
    text-align: center;
  }

  .registration-title {
    text-align: center;
  }

  .event-top-date {
    display: none;
  }

  .event-top-dt {
    margin-left: 0;
  }

  .event-main-title {
    font-size: 24px;
  }

  .event-type-name {
    font-size: 14px;
  }

  .countdown-item {
    padding: 10px 0;
  }

  .user-right-dt {
    margin-top: 40px;
  }

  .tab-link {
    width: 100%;
  }

  .custom-tabs .tab-link.active,
  .tab-tabs .show > .tab-link {
    color: #fff;
    background-color: #1d3f73;
    border-bottom: 2px solid #1d3f73;
    border-radius: 3px;
  }

  .custom-tabs .tab-link.active:hover {
    color: #fff;
    background-color: #1d3f73;
    border-bottom: 2px solid #1d3f73;
  }

  .custom-tabs .tab-link:hover {
    color: #1d3f73;
    background-color: #fff;
    border-bottom: 2px solid transparent;
  }

  .card-top {
    display: block;
    width: 100%;
  }

  .card-event-img {
    width: 100%;
    height: 160px;
    margin-bottom: 20px;
    margin-right: 0;
  }
  .season-card-top {
    flex-wrap: wrap;
  }
  .event-top .card-event-img {
    width: 100%;
    height: 160px;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .card-bottom-item {
    width: 50% !important;
  }

  .card-bottom-item {
    display: block;
    padding: 20px;
    text-align: center;
  }

  .card-dt-text {
    margin-left: 0;
  }

  .card-icon i {
    margin-bottom: 15px;
  }

  .my-event-tabs {
    display: flex;
  }
  .garren-line-tab {
    display: flex;
    flex-wrap: nowrap;
  }
  .event-link-org {
    /* width: 100% !important; */
  }
  .transfer-btn {
    padding: 0% !important;
  }
  .esv-btn {
    padding: 0% !important;
  }
  .css-8r6hqs {
    width: fit-content !important;
  }
  .popup-about {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .modal-dialog-scrollable .modal-content {
    max-height: 100% !important;
  }
  .res-main-btn {
    padding: 0 20px !important;
  }
  .res-dropdown-header {
    margin-right: -90% !important;
  }
  .org-button-create {
    display: grid !important;
    justify-content: center !important;
    gap: 5px !important;
  }
  .leagueinputSty {
    display: flex !important;
    gap: 6rem !important;
  }
  .league-date-input {
    width: 100% !important;
  }
  .league-cancel-btn {
    padding: 0 4px !important;
  }
  .res-stadium {
    scale: 0.6;
    margin-left: -30%;
  }
  .seatscroll-season {
    overflow-y: hidden;
    overflow-x: scroll;
    width: 300px;
  }
  .stadium-box {
    width: 80vw;
  }
  .OwnerTable {
    width: 200% !important;
  }
  .stadiumSecTable {
    width: 100% !important;
  }

  .owner-email-season {
    width: 250px;
  }
  .res-payment-season {
    width: 100% !important;
  }
  .res-create-seat-input {
    width: 150px !important;
  }
  .res-create-seat-price-input {
    width: 80px !important;
  }
  .res-savepub-btn {
    padding: 0px 0px !important;
  }
  .event-link {
    width: 100%;
    border-bottom: none;
  }

  .my-event-tabs .event-link.active,
  .my-event-tabs .show > .event-link {
    color: #fff !important;
    background-color: #1d3f73 !important;
    border-bottom: none !important;
  }

  .my-event-tabs .event-link.active span,
  .my-event-tabs .show > .event-link span {
    color: #fff !important;
  }

  .my-event-tabs .event-link.active .event-count,
  .my-event-tabs .show > .event-link .event-count {
    color: #fff !important;
    background: #7fdb57 !important;
  }

  .event-link {
    padding: 1.5rem !important;
  }

  .user-follow-card {
    display: block;
  }

  .follow-card-btn {
    margin-left: 77px;
  }

  .add-organisation-card {
    display: block;
    text-align: center;
  }

  .ocard-left {
    display: block;
  }

  .ocard-avatar {
    display: inline-block;
    margin: 0;
  }

  .ocard-name {
    margin-left: 0;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .rs {
    margin-top: 27px;
  }

  .custom2-tabs .tab-link {
    width: 100%;
    display: block;
    margin: 0 0 5px 0 !important;
  }

  .relative-input .form-control {
    width: 100%;
  }

  .event-list .event-top .dropdown {
    position: absolute;
    right: 26px;
    top: 26px;
  }

  .option-btn {
    color: rgba(255, 255, 255, 0.7);
  }

  .option-btn:hover {
    color: rgba(255, 255, 255, 1);
    border: 1px solid #fff;
  }

  .contact-list .top .icon-box {
    min-width: 100%;
    margin-bottom: 15px;
  }

  .contact-list .bottom .icon-box {
    min-width: 200px;
    margin-bottom: 30px;
  }

  .rmt_4 {
    margin-top: 5px;
  }

  .tracking-tab .nav-tabs .nav-item {
    margin-bottom: 10px;
    width: 100%;
  }

  .organisation-profile {
    max-width: 100%;
    min-width: 100%;
  }

  .profile-edit-btn {
    right: 20px;
  }

  .upgrade-content .upgrade-premium-center {
    max-width: 100%;
  }

  .upgrade-banner img {
    height: auto;
  }

  .brn-checkbox-radio {
    padding-left: 30px;
  }

  .br-checkbox {
    position: absolute;
    left: 0px;
  }

  .input-content {
    padding-left: 30px;
  }

  .price-ticket-card-head .top-name .icon-box {
    min-width: 100%;
    margin-bottom: 15px;
  }

  .price-ticket-card .price-ticket-card-body .icon-box {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .price-ticket-card .price-ticket-card-body .icon-box:last-child {
    margin-bottom: 0;
  }

  .sell-calculate-banner-bg {
    max-width: 100%;
  }

  .flex-slide {
    display: block;
  }

  .flex-slide .left {
    flex: 0 1 100%;
  }

  .blog-img-card img {
    width: 100%;
    max-height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
  }

  .flex-slide .right {
    padding: 1.5rem;
  }

  .flex-slide .right .blog--title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .blog-block .right .post-meta span {
    font-size: 14px;
  }

  .blog-block .right p {
    font-size: 14px;
  }

  .prev_title {
    max-width: 100px !important;
  }

  .communities-item {
    height: auto;
  }

  .easy-step {
    height: auto;
  }

  .coming-soon-content h2 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .coming-soon-content #timer div {
    width: 75px;
    height: 75px;
    font-size: 24px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .coming-soon-content {
    padding: 37px 20px;
  }

  .error-404-content p {
    padding: 0 0;
  }

  .calculate-top {
    padding: 5px 20px 20px;
  }

  .blog-img-card img {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .res-main-logo {
    display: inline-block;
  }
  .stadium-box {
    width: 80vw;
  }
  .res-main-logo img {
    height: 40px;
  }

  .main-logo {
    display: none;
  }

  .create-btn {
    padding: 5px 15px;
  }

  .create-btn {
    padding: 5px 10px;
    display: none;
  }

  .create-btn span {
    display: none;
  }

  .create-btn i {
    margin-right: 0;
  }

  .navbar-nav .nav-link {
    padding: 12px 25px 12px 20px !important;
    font-size: 1.1em;
  }
  .lang-select-box {
    margin: 12px 25px 12px 20px !important;
  }
  .navbar-nav .nav-link:hover {
    color: #1d3f73 !important;
    background-color: #e8f7f7;
  }

  .pe_5 {
    padding-right: 0 !important;
  }

  .offcanvas-header {
    border: 1px solid #efefef;
  }

  .offcanvas-logo img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .offcanvas-body {
    padding: 0 0 1rem !important;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    border: 0 !important;
    box-shadow: none !important;
    padding: 0 0 0 20px !important;
  }

  .navbar-nav .dropdown-menu .dropdown-item {
    font-size: 18px;
    padding: 0.6rem 1rem !important;
    color: #000;
  }

  .dropdown-submenu .dropdown-item:hover,
  .dropdown-submenu .dropdown-item:focus {
    color: #1d3f73 !important;
    background-color: transparent !important;
  }

  .navbar-nav .dropdown-menu .dropdown-item::before {
    content: "\f068";
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
  }

  .navbar-nav .dropdown-toggle::after {
    display: inline-block;
    float: right;
    margin-top: 10px;
  }

  .submenu-item:after {
    content: "\f0dd";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    float: right;
    font-size: 12px;
    padding-right: 10px;
  }

  .offcanvas-top-area {
    display: block;
    padding: 0 0 20px;
    text-align: center;
  }

  .create-bg {
    background-color: #f1f2f3;
    border-radius: 0;
    padding: 30px;
  }

  .dropdown-submenu {
    width: 100%;
  }

  .hero-banner-content h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .hero-banner-content p {
    padding: 0;
  }

  .easy-steps-tab .nav {
    display: block;
    width: 100%;
  }

  .step-link {
    width: 100%;
  }

  .main-title h3 {
    font-size: 24px;
    line-height: 42px;
  }

  .p-80 {
    padding: 64px 0 64px;
  }

  .app-form-sidebar {
    display: none;
  }

  .sign-logo {
    display: block;
  }

  .app-top-right-link {
    display: none;
  }

  .app-top-items {
    display: block;
    width: 100%;
    text-align: center;
  }

  .registration-title {
    text-align: center;
  }

  .contact-list .bottom .icon-box {
    min-width: 270px;
    margin-bottom: 30px;
  }

  .event-top-date {
    display: none;
  }

  .event-top-dt {
    margin-left: 0;
  }

  .event-main-title {
    font-size: 24px;
  }

  .event-type-name {
    font-size: 14px;
  }

  .user-right-dt {
    margin-top: 40px;
  }

  .tab-link {
    width: 100%;
  }

  .custom-tabs .tab-link.active,
  .tab-tabs .show > .tab-link {
    color: #fff;
    background-color: #1d3f73;
    border-bottom: 2px solid #1d3f73;
    border-radius: 3px;
  }

  .custom-tabs .tab-link.active:hover {
    color: #fff;
    background-color: #1d3f73;
    border-bottom: 2px solid #1d3f73;
  }

  .custom-tabs .tab-link:hover {
    color: #1d3f73;
    background-color: #fff;
    border-bottom: 2px solid transparent;
  }

  .avatar-img-btn {
    left: 60%;
  }

  .card-top {
    display: block;
    width: 100%;
  }

  .card-event-img {
    width: 100%;
    height: 160px;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .card-bottom-item {
    width: 50%;
  }

  .card-bottom-item {
    display: block;
    padding: 20px;
    text-align: center;
  }

  .card-dt-text {
    margin-left: 0;
  }

  .card-icon i {
    margin-bottom: 15px;
  }

  .custom2-tabs .tab-link {
    display: block;
    margin: 0 5px 5px 0 !important;
  }

  .mt_r4 {
    margin-top: 1.5rem;
  }

  .relative-input .form-control {
    width: 100%;
  }

  .event-top .card-event-img {
    width: 100%;
    height: 160px;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .event-list .event-top .dropdown {
    position: absolute;
    right: 26px;
    top: 26px;
  }

  .option-btn {
    color: #fff;
  }

  .rmt_4 {
    margin-top: 5px;
  }

  .upgrade-banner img {
    height: auto;
  }

  .brn-checkbox-radio {
    padding-left: 30px;
  }

  .br-checkbox {
    position: absolute;
    left: 0px;
  }

  .input-content {
    padding-left: 30px;
  }

  .price-ticket-card-head .top-name .icon-box {
    min-width: 100%;
    margin-bottom: 15px;
  }

  .price-ticket-card .price-ticket-card-body .icon-box {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .price-ticket-card .price-ticket-card-body .icon-box:last-child {
    margin-bottom: 0;
  }

  .sell-calculate-banner-bg {
    max-width: 100%;
  }

  .flex-slide {
    display: block;
  }

  .flex-slide .left {
    flex: 0 1 100%;
  }

  .blog-img-card img {
    width: 100%;
    max-height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
  }

  .flex-slide .right {
    padding: 1.5rem;
  }

  .flex-slide .right .blog--title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .blog-block .right .post-meta span {
    font-size: 14px;
  }

  .blog-block .right p {
    font-size: 14px;
  }

  .prev_title {
    max-width: 100px !important;
  }

  .communities-item {
    height: auto;
  }

  .easy-step {
    height: auto;
  }

  .coming-soon-content h2 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .coming-soon-content #timer div {
    width: 75px;
    height: 75px;
    font-size: 24px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .coming-soon-content {
    padding: 37px 30px;
  }

  .error-404-content p {
    padding: 0 0;
  }

  .calculate-top {
    padding: 5px 20px 20px;
  }

  .blog-img-card img {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: 12px 25px 12px 20px !important;
    font-size: 1.1em;
  }
  .stadium-box {
    width: 80vw;
  }
  .lang-select-box {
    margin: 12px 25px 12px 20px !important;
  }
  .navbar-nav .nav-link:hover {
    color: #1d3f73 !important;
    background-color: #e8f7f7;
  }

  .pe_5 {
    padding-right: 0 !important;
  }

  .offcanvas-header {
    border: 1px solid #efefef;
  }

  .offcanvas-logo img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .offcanvas-body {
    padding: 0 0 1rem !important;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    border: 0 !important;
    box-shadow: none !important;
    padding: 0 0 0 20px !important;
  }

  .navbar-nav .dropdown-menu .dropdown-item {
    font-size: 18px;
    padding: 0.6rem 1rem !important;
    color: #000;
  }

  .dropdown-submenu .dropdown-item:hover,
  .dropdown-submenu .dropdown-item:focus {
    color: #1d3f73 !important;
    background-color: transparent !important;
  }

  .navbar-nav .dropdown-menu .dropdown-item::before {
    content: "\f068";
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
  }

  .navbar-nav .dropdown-toggle::after {
    display: inline-block;
    float: right;
    margin-top: 10px;
  }

  .submenu-item:after {
    content: "\f0dd";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    float: right;
    font-size: 12px;
    padding-right: 10px;
  }

  .offcanvas-top-area {
    display: block;
    padding: 0 0 20px;
    text-align: center;
  }

  .create-bg {
    background-color: #f1f2f3;
    border-radius: 0;
    padding: 30px;
  }

  .dropdown-submenu {
    width: 100%;
  }

  .hero-banner-content h2 {
    font-size: 30px;
    line-height: 42px;
  }

  .easy-steps-tab .nav {
    width: 100%;
  }

  .step-link {
    width: 50%;
  }

  .step-link:first-child {
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    border-top: 1px solid #efefef;
  }

  .step-link:last-child {
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
  }

  .step-link:nth-child(2) {
    border-right: 1px solid #efefef;
    border-top: 1px solid #efefef;
  }

  .step-link:nth-child(3) {
    border-left: 1px solid #efefef;
  }

  .feature-step-link:nth-child(4) {
    border-right: 1px solid #efefef;
    border-left: 1px solid #efefef;
  }

  .bg-dark .step-link:first-child {
    border-left: 1px solid #52565b;
    border-right: 1px solid #52565b;
    border-top: 1px solid #52565b;
  }

  .bg-dark .step-link:last-child {
    border-left: 1px solid #52565b;
    border-right: 1px solid #52565b;
  }

  .bg-dark .step-link:nth-child(2) {
    border-right: 1px solid #52565b;
    border-top: 1px solid #52565b;
  }

  .bg-dark .step-link:nth-child(3) {
    border-left: 1px solid #52565b;
  }

  .app-form-sidebar {
    display: none;
  }

  .sign-logo {
    display: block;
  }

  .app-top-right-link {
    display: none;
  }

  .app-top-items {
    display: block;
    width: 100%;
    text-align: center;
  }

  .registration-title {
    text-align: center;
  }

  .user-right-dt {
    margin-top: 40px;
  }

  .avatar-img-btn {
    left: 57%;
  }

  .card-bottom-item {
    width: 50%;
  }

  .card-bottom-item {
    display: block;
    padding: 20px;
    text-align: center;
  }

  .card-dt-text {
    margin-left: 0;
  }

  .card-icon i {
    margin-bottom: 15px;
  }

  .subscription-item {
    height: 205px;
  }

  .brn-checkbox-radio {
    padding-left: 30px;
  }

  .br-checkbox {
    position: absolute;
    left: 0px;
  }

  .input-content {
    padding-left: 30px;
  }

  .sell-calculate-banner-bg {
    max-width: 100%;
  }

  .flex-slide .left {
    flex: 0 1 40%;
  }

  .blog-img-card img {
    width: 100%;
    max-height: 600px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
  }

  .flex-slide .right {
    padding: 1.5rem;
  }

  .communities-item {
    height: auto;
    display: flex;
    align-items: stretch;
    transition: 300ms;
    position: relative;
  }

  .communities-img {
    flex: 0 1 40%;
    padding: 10px 10px 10px;
  }

  .communities-img img {
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
    height: 100%;
  }

  .communities-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
  }

  .easy-step {
    height: auto;
  }

  .error-404-content p {
    padding: 0 0;
  }

  .kb-card {
    display: block;
    height: 190px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-expand-lg .offcanvas-footer {
    display: none;
  }
  .stadium-box {
    width: 80vw;
  }
  .modal-myteam {
    width: 55% !important;
    background-color: #fff;
    height: 28% !important;
    margin-top: 6%;
    margin-left: 21% !important;
    display: block;
    border-radius: 7px;
  }
  .dateInputSty {
    width: 100% !important;
  }
  .card-event-img-report img {
    margin-left: -5px !important;
    width: 75% !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .create-btn {
    padding: 8px 12px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .create-btn span {
    display: none;
  }

  .create-btn i {
    margin-right: 0;
  }

  .app-form-sidebar {
    display: none;
  }

  .sign-logo {
    display: block;
  }

  .new-sign-link {
    display: none;
  }

  .card-bottom-item {
    width: 50%;
  }

  .card-bottom-item {
    display: block;
    padding: 20px;
    text-align: center;
  }

  .card-dt-text {
    margin-left: 0;
  }

  .card-icon i {
    margin-bottom: 15px;
  }

  .subscription-item {
    height: 230px;
  }

  .brn-checkbox-radio {
    padding-left: 30px;
  }

  .br-checkbox {
    position: absolute;
    left: 0px;
  }

  .input-content {
    padding-left: 30px;
  }

  .flex-slide .right {
    padding: 1.5rem;
  }

  .kb-card {
    display: block;
    height: 190px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .navbar-expand-lg .offcanvas-footer {
    display: none;
  }
  .card-event-img-report img {
    width: 100% !important;
    /* margin-left: 0px !important; */
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }

  .new-sign-link {
    display: none;
  }

  .pe_right {
    padding-right: 150px;
  }

  .ticket-instructions-content {
    padding-left: 55px;
  }

  .kb-card {
    display: block;
    height: 190px;
  }
}

/* desktop view */
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }

  .dropdown-menu .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .dropdown-menu .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}
.bgBlur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.starsty {
  color: red;
}
.validation-err {
  color: red;
}

iframe {
  display: none !important;
}
.side-logo-outerPage {
  height: 30%;
  width: 62%;
}

/* Typography CSS for Live Stream Sports Ticketing Website */

/* Import Inter font in WOFF2 format */
@font-face {
  font-family: 'Inter';
  src: url('https://fonts.gstatic.com/s/inter/v3/UcCo3FwrnkI2a0ZjSjUt.woff2') format('woff2');
  font-weight: 100 900;
  font-display: swap;
}

/* Apply global styles */
body {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5em;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

/* Paragraphs */
p {
  margin: 0 0 1em;
}

/* Lists */
ul, ol {
  margin: 0 0 1em;
  padding-left: 1.5em;
}

li {
  margin: 0.5em 0;
  line-height: 1.5;
}

/* Blockquotes */
blockquote {
  margin: 1.5em 0;
  padding: 1em 1.5em;
  background-color: #f1f1f1;
  border-left: 4px solid #0073e6;
  font-style: italic;
  color: #555;
}

/* Emphasis and Strong */
em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

/* Links */
a {
  color: #0073e6;
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

/* Code Elements */
code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.875rem;
  background-color: #f4f4f4;
  padding: 0.2em 0.4em;
  border-radius: 4px;
}

pre {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.875rem;
  background-color: #f4f4f4;
  padding: 1em;
  border-radius: 4px;
  overflow-x: auto;
}

/* Buttons */
button {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.primary {
  background-color: #1d3f73;
  color: #fff;
}

button.primary:hover {
  background-color: #163459;
}

button.secondary {
  background-color: #7895c2;
  color: #fff;
}

button.secondary:hover {
  background-color: #60779a;
}

/* Text Secondary Color */
.text-secondary {
  color: #6b6b6b;
}

/* Responsive Typography */
@media (max-width: 768px) {
  body {
    font-size: 15px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}