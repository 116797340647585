.seasonal-card {
  width: 47%;
}
.landing-header-logo img {
  width: 100px;
  height: 100px;
  margin-top: -30px;
  border-radius: 9999px;
  outline-color: rgb(9, 153, 161);
  outline-style: solid;
}

.seasonal-card .resell-img-style {
  width: 225px !important;
  height: 147px;
}
.hero-banner-landing {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 0 30px;
}
.landing-main-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.hero-banner-landing-new {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 10px 0 30px; */
  padding: 0px;
  /* padding: 100px 40px 190px; */
}
.landing-main-img-new {
  /* object-fit: cover; */
  width: 100%;
  /* padding: 0px; */
  height: 482px;
  /* padding: 0px 0px 190px; */
}

.control:hover {
  background: #ffffff;
  border-bottom: #1d3f73;
}

.nav-link-btn {
  color: #000 !important;
  height: 40px;
  padding: 0 20px !important;
  width: 190px !important;
  background-color: #ffffff !important;
}

.nav-link-btn:hover {
  color: #1d3f73 !important;
  background-color: #ffffff;
}
.nav-link-btn.active {
  background-color: #ffffff !important;
  border-bottom: 3px solid #1d3f73 !important;
  color: #1d3f73 !important;
}
@media only screen and (min-width: 320px) and (max-width: 900px) {
  .landing-page-buttons {
    display: block;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .season-pass-section {
    display: block !important;
  }
  .seasonal-card {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .responsive-card-img {
    height: 0% !important;
  }
}
.scrollable-buttons-container {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* Optional: Enable smooth scrolling on iOS */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.scrollable-buttons-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
}

/* Optional: Adjust the appearance of the buttons */
.nav-link-btn {
  min-width: 120px; /* Adjust as needed */
  margin-right: 10px; /* Adjust spacing between buttons */
}
