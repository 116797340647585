/* Event Type */
.checkout-title {
  border-bottom: 2px solid #efefef;
  padding-bottom: 25px;
}
.create-card {
  text-align: center;
  padding: 30px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%);
}
.create-icon {
  display: inline-block;
}
.create-icon {
  font-size: 20px;
  color: #000;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #e8f7f7;
  border-radius: 50%;
  margin-bottom: 22px;
}
.create-card h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 27px;
  color: #000;
}
.night-mode .create-card h4 {
  color: white;
}
.event-type-wrapper {
  margin-top: 75px;
}
.night-mode .eventtype-nightmode {
  background-color: #111315 !important;
  color: #6f767e !important;
}
.event-type-iconsty {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  margin-top: -5px;
}

/* Create Event */
.Create-event-wrapper {
  margin-top: 75px;
}
.Create-league-wrapper {
  margin-top: 58px;
}
.wizard-steps-block {
  margin-top: 39px;
}

#add-event-tab {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

#add-event-tab .step-steps {
  /* display: -webkit-box;
	display: -ms-flexbox; */
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 0;
}

#add-event-tab .step-steps > li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  max-width: 0 0 33.3333%;
}

#add-event-tab .step-steps > li:before {
  background-color: #ededed;
  height: 5px;
  left: 50%;
  top: 20px;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#add-event-tab .step-steps > li > a > .number {
  width: 24px;
  height: 24px;
  background-color: #d1d1d1;
  border: 0px solid;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#add-event-tab .step-steps > li > a > .number:before {
  background-color: rgba(221, 221, 211, 0.3);
  box-shadow: 0 0 0 rgba(221, 221, 211, 0);
  height: calc(100% + 16px);
  width: calc(100% + 16px);
  top: -8px;
  left: -8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#add-event-tab .step-steps > li > a > .step-name {
  font-weight: 500;
  color: #b2b2b2;
  font-size: 14px;
  margin-top: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: capitalize;
}

#add-event-tab .step-steps > li:last-child:before {
  width: 0;
}

#add-event-tab .step-steps > li.active > a > .number,
#add-event-tab .step-steps > li.done > a > .number {
  background-color: #1d3f73;
}

#add-event-tab .step-steps > li.active > a > .number:before,
#add-event-tab .step-steps > li.done > a > .number:before {
  background-color:rgb(69 125 192 / 30%)
}

#add-event-tab .step-steps > li.active > a > .step-name,
#add-eventevent-tab .step-steps > li.done > a > .step-name {
  color: #333;
}

#add-event-tab .step-steps > li.done:before {
  background-color: #1d3f73;
}

#add-event-tab .step-steps > li.done > a > .number:after {
  display: none;
}

#add-event-tab > .step-content {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  border: 0px solid;
  border-radius: 0px;
  padding-top: 35px;
}

#add-event-tab > .step-content .title-icon {
  margin-bottom: 25px;
}

.p_30 {
  padding: 30px !important;
}

.pb_30 {
  padding-bottom: 30px !important;
}

.pt_30 {
  padding-top: 30px !important;
}

.pl_30 {
  padding-left: 30px !important;
}

.pr_30 {
  padding-right: 30px !important;
}

.min-h-auto {
  min-height: auto !important;
}

.clock-icon .bootstrap-select > .dropdown-toggle:after {
  content: "\f017";
  font-family: "Font Awesome 6 free";
  border: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  position: absolute;
  right: 20px;
  top: 37%;
}

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #265399 !important;
}

.datepicker--cell.-current- {
  color: #fff !important;
  background: #1d3f73 !important;
}

.absolute-icon {
  color: #717171;
  font-weight: 600;
  position: absolute;
  background: transparent;
  right: 5px;
  top: 5px;
  min-height: 40px;
  padding: 0 15px;
  line-height: 40px;
  margin-bottom: 0;
  border-radius: 3px;
  pointer-events: none;
}

.absolute-icon:hover {
  color: #1d3f73;
}

.default-event-thumb {
  border: 1px dashed #bbb;
  height: 240px;
  overflow: hidden;
  position: relative;
}

.default-event-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.line-height-32 {
  line-height: 32px !important;
}

.default-event-thumb-btn {
  display: inline-block;
  color: #e44d3a;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 600;
  margin-right: 0;
  width: 100%;
}

.default-event-thumb-btn .thumb-change-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  float: left;
}

.default-event-thumb-btn .thumb-change-btn [type="file"] + label {
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 12px 30px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0;
  float: right;
}

.default-event-thumb-btn .thumb-change-btn [type="file"] + label:hover {
  background: #265399;
  color: #fff;
}

.stepper-data-set .content-holder label {
  font-size: 14px;
  font-weight: 500;
}

.template-select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
}

.template-item img {
  display: block;
  margin: 0;
  width: 100%;
}

.template-item {
  position: relative;
}

.template-item input {
  position: absolute;
  z-index: 999;
  left: 10px;
  top: 10px;
}

.template-item input:active + .template {
  opacity: 0.9;
}

.template-item input:checked + .template {
  border-radius: 5px;
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.template {
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
}

#add-event-tab .step-footer.step-tab-pager {
  text-align: center;
}

.step-app > .step-footer {
  margin-top: 40px !important;
  margin-bottom: 0 !important;
}

.steps_btn {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif !important;
  color: #fff !important;
  background: #1d3f73 !important;
  padding: 0px 30px !important;
  width: 150px;
  border-radius: 3px !important;
  border: 0 !important;
  height: 40px !important;
  line-height: 40px !important;
}

.steps_btn:hover {
  color: #fff !important;
  background: #265399 !important;
}

.steps_btn:focus {
  box-shadow: none !important;
}

.dropdown.bootstrap-select.dropdown-no-bg .dropdown-toggle {
  background: transparent !important;
  border: none !important;
}

.pp-select {
  position: absolute;
  right: 0;
  width: 90px;
  z-index: 999;
}

.hosting-title {
  text-align: center;
}

input[type="number"] {
  /*for absolutely positioning spinners*/
  position: relative;
  padding-right: 25px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.brn-checkbox-radio {
  display: table;
  position: relative;
  padding-left: 55px;
  cursor: pointer;
  margin-bottom: 13px;
  line-height: 23px;
}

.input-content {
  padding-left: 75px;
}

.percentage-icon {
  color: #717171;
  font-weight: 600;
  position: absolute;
  background: transparent;
  right: 0px;
  top: 1px;
  min-height: 40px;
  padding: 0px 15px;
  line-height: 40px;
  margin-bottom: 0;
  border-radius: 3px;
  pointer-events: none;
  z-index: 999;
  font-size: 18px;
}

.sub-label {
  color: #717171;
}

.form-check-input:checked {
  background-color: #1d3f73 !important;
  border-color: #1d3f73 !important;
  outline: #a2ff7a !important;
  box-shadow: none !important;
}

.form-check-input {
  width: 1.2em !important;
  height: 1.2em !important;
}

.refund-method:first-child .refund-input-content {
  display: block;
}

.refund-method .refund-input-content {
  display: none;
}

.br-checkbox {
}

.ticket-instructions-content {
  padding-left: 55px;
}

.tags-list {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 8px;
}

.tag {
  background-color: rgba(42, 65, 232, 0.07);
}

.tag {
  background-color: rgba(106, 192, 69, 0.07);
  color: #1d3f73;
  display: inline-block;
  padding: 0;
  border-radius: 3px;
  cursor: default;
  margin: 0 7px 7px 0;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  animation: KeywordIn 0.3s ease-in-out;
  animation-fill-mode: both;
  transition: 0.4s;
  overflow: hidden;
  max-width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.tag-text {
  padding-right: 12px;
}

.tag .tag-remove:before {
  display: inline-block;
  cursor: pointer;
  font-family: "Font Awesome 6 Free";
  content: "\f00d";
  line-height: 20px;
  position: relative;
  top: 0px;
  margin-right: 7px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 900;
}

#map {
  height: 250px;
  width: 100%;
}

.full-width {
  width: 100% !important;
}

.price-ticket-card {
  border: 1px solid #efefef;
  border-radius: 3px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.ticket-list-icon {
  width: 110px;
  height: 110px;
  background: #fafafa;
  border: 1px solid #efefef;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  padding-top: 0;
  line-height: 110px;
}

.ticket-list-icon img {
  width: 70px;
}

.price-badge {
  width: 24px;
  height: 24px;
  padding-top: 2px;
  margin-right: 15px;
}

.price-badge img {
  width: 100%;
  object-fit: cover;
}

.price-ticket-card-head .top-name .icon-box .icon {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #d4f7f7;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 2px;
}

.price-ticket-card-head .top-name .icon-box {
  position: relative;
  padding-left: 66px;
}

.price-ticket-card .price-ticket-card-body .text-145 {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #717171;
  margin: 0;
}

.price-ticket-card .price-ticket-card-body h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  margin: 0 0 6px;
}

.price-ticket-card .price-ticket-card-body .icon-box:not(:last-child) {
  padding-right: 15px;
}

.price-ticket-card .price-ticket-card-body .icon-box {
  position: relative;
  padding-left: 52px;
  width: 33.3333%;
  max-width: 100%;
}

.price-ticket-card .price-ticket-card-body .icon-box .icon {
  display: inline-block;
  font-size: 14px;
  height: 36px;
  width: 36px;
  color: #717171;
  background: #f5f7fd;
  border-radius: 100px;
  text-align: center;
  line-height: 35px;
  position: absolute;
  left: 0;
  top: 0;
}

.price-variation-head {
  padding: 16px;
  background: #f5f8ff;
  box-shadow: 0px -1px 0px rgb(0 0 0 / 6%);
  filter: drop-shadow(0px -10px 10px rgba(0, 0, 0, 0.05));
  margin-top: 20px;
}

.add-btn {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #1d3f73;
  border-radius: 3px;
  cursor: pointer;
}

.div-base-table.small-table {
  min-width: 440px;
}

.div-base-table {
  display: table;
  min-width: 873px;
  border: 1px solid #dddfe2;
}

.div-base-table .table-row.table-head {
  background: #fafafa;
  color: #000;
  font-weight: 500;
  font-size: 13px;
}

.div-base-table .table-row {
  display: table-row;
  font-size: 12px;
  color: #4b4c4c;
}

.no-bg {
  background: none !important;
  background-color: transparent !important;
}

.div-base-table .table-row .table-col {
  display: table-cell;
  padding: 10px 10px 10px 0px;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 0 #dddfe2;
  -moz-box-shadow: 0 1px 0 #dddfe2;
  box-shadow: 0 1px 0 #dddfe2;
  overflow: hidden;
}

.text-light3 {
  color: #000 !important;
}

.div-base-table .table-row {
  display: table-row;
  font-size: 12px;
  color: #000;
}

.v-btn-close {
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 42px;
  text-align: center;
  background: #1d3f73;
  border-radius: 3px;
  border: 0;
  font-size: 18px;
}

.top_0 {
  top: 0 !important;
}

.step-app > .step-steps {
  overflow: hidden;
  margin: 0 auto;
}

.step-app > .step-steps > li {
  display: inline-block;
  text-align: center;
  width: 24%;
  position: relative;
  z-index: 1;
}

.step-app > .step-steps > li:before {
  position: absolute;
  content: "";
  z-index: -1;
  width: 185px;
  height: 2px;
  background-color: #c4cad2;
  top: 35px;
  left: 85px;
}

.step-app > .step-steps > li:last-child:before {
  background-color: transparent;
}

.step-app > .step-steps > li.done:before {
  background-color: #fdb51c;
}

.step-app > .step-steps > li > a,
.step-app > .step-steps > li > a > span {
  display: block;
}

.step-app > .step-steps > li > a > .number {
  background-color: #dae1e8;
  border: 1px solid #b6c2cf;
  border-radius: 50%;
  color: #454e5b;
  font-size: 18px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  margin: 10px auto;
  position: relative;
  width: 50px;
  z-index: 0;
}

.step-app > .step-steps > li > a > .number::before,
.step-app > .step-steps > li.done > a > .number::after {
  border-radius: inherit;
  box-shadow: 1px 1px 5px #d1d7de;
  height: 36px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 36px;
  background-color: #fff;
}

.step-app > .step-steps > li > a > .number::before {
  content: "";
  z-index: -1;
}

.step-app > .step-steps > li.done > a > .number::after {
  content: "\f00c";
  z-index: 1;
  font-family: fontawesome;
  line-height: 35px;
}

.step-app > .step-steps > li > a > .step-name {
  color: #47494e;
  font-size: 13px;
  font-weight: 800;
  text-transform: uppercase;
}

.step-app > .step-steps > li.error a {
  color: #fff;
}

.step-app > .step-steps > li.done > a > .number {
  background-color: #fdb51c;
  border-color: #d79a18;
}

.step-app > .step-content {
  border: 1px solid #e5e5e5;
  padding-top: 55px;
  border-top: 0;
}

.step-app > .step-content > .step-tab-panel {
  display: none;
}

.step-app > .step-content > .step-tab-panel.active {
  display: block;
}

.step-app > .step-footer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.step-app > .step-footer > .step-btn {
  padding: 4px 16px;
  color: #333;
  text-decoration: none;
  background: #e5e5e5;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}
.create-event-stadium {
  scale: 0.8;
  margin-top: -40px;
}
path.svg_inactive {
  fill: #f2f2f2;
}
.svg_sektor {
  stroke: #000000;
  stroke-width: 1.5;
}
.ce-border {
  border-bottom: 1px solid #efefef;
}
.tableSty {
  border: 1px solid black;
  /* margin: 10px; */
  /* width: 95%; */
}
.tableHeadSty {
  border: 1px solid white;
  background-color: #1d3f73;
  color: white;
  text-align: center;
  letter-spacing: 1px;
}
.tableDataSty {
  border: 1px solid white;
  text-align: center;
  width: max-content;
}
.configure-btn {
  width: 100px !important;
  padding: 0% !important;
}
.res-config-btn{
  width: 150px !important;
}
.currency-input-create {
  width: 70px !important;
}
.memorabilia-img-div {
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
  margin: 30px 0px 30px 0px;
}
.memorabilia-img {
  height: 220px;
  width: 300px;
  border-radius: 10px;
}
.memorabilia-cross-icon {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  background-color: #e76161;
  height: 30px;
  width: 30px;
  color: white;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.memorabilia-filename {
  overflow-wrap: break-word !important;
  width: 300px;
}
.memorabilia-mul-imgs {
  position: relative;
}
.eventImg {
  height: 100px !important;
  width: 100px !important;
  border-radius: 15px;
  margin-inline-start: 10px;
  margin-top: 10px;
}

/* Configure Seats CSS*/
.seats {
  /* display: flex; */
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.seat {
  display: flex;
  /* flex: 0 0 14.28571%; */
  /* padding: 5px; */
  width: 76px;
  height: max-content;
  position: relative;
}
.seatcurrencyinput{
  width: 8% !important;
}
/* .seat:nth-child(3) {
    margin-right: 14.28571%;
  } */
.seat input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
.seat input[type="checkbox"]:checked + label {
  background: #bada55;
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.seat input[type="checkbox"]:disabled + label {
  background: #ddd;
  color: white;
  overflow: hidden;
}

.seat input[type="checkbox"]:disabled + label:after {
  /* content: ""; */
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.seat input[type="checkbox"]:disabled + label:after {
  /* content: ""; */
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.seat input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.seat label {
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.5rem;
  padding: 4px 0;
  color: #fff;
  background: #26a69a;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
}
.seat label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}
.seatDisable label {
  background: #ddd;
}
.seatDisable label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}
.seatConfigured label {
  background: #db6977;
}
.seatConfigured label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}
.Create-Conf-Btn {
  width: max-content;
}
.uploadinput {
  padding: 14px 15px !important;
}
.dateInputSty {
  width: 257px;
}
@media (max-width: 575.98px) {
  .Upload-image-event {
    width: 86% !important;
  }
}
.Upload-image-Modal {
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
}

/* seat configuration */
.ulListAs {
  list-style: none;
}

#circle1 {
  background: #db6977;
  border-radius: 50%;
  width: 17px;
  height: 17px;
}
#circle2 {
  background: #d9d9d9;
  border-radius: 50%;
  width: 17px;
  height: 17px;
}
#circle3 {
  background: #bada55;
  border-radius: 50%;
  width: 17px;
  height: 17px;
}
#circle4 {
  background: #26a69a;
  border-radius: 50%;
  width: 17px;
  height: 17px;
}

.seatscroll {
  overflow-y: hidden;
  overflow-x: scroll;
}

.seatscroll::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.seatscroll::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 18px;
  border: 3px solid white;
}
.night-mode .popup-main-title {
  color: #000000 !important;
}
