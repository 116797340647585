

.seasonSoldOutSeat {
  display: flex;
  padding: 5px;
  gap: 10px;
  position: relative;
}
.seasonSoldOutSeat input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.seasonSoldOutSeat input[type="checkbox"]:disabled + label {
  background: #ddd !important;
  color: white !important;
  overflow: hidden !important;
  border: 3px solid #db6977 !important;
}
.seasonSoldOutSeat input[type="checkbox"]:disabled + label:after {
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.seasonSoldOutSeat input[type="checkbox"]:disabled + label:after {
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.seasonSoldOutSeat input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.seasonSoldOutSeat label {
  display: block;
  position: relative;
  width: 100% !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1rem;
  padding: 2px;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
  border: 3px solid #db6977 !important;
}
.seasonSoldOutSeat label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}


/*  */

.selectSeasonSeat {
  display: flex;
  padding: 5px;
  gap: 10px;
  position: relative;
}
.selectSeasonSeat input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
.selectSeasonSeat input[type="checkbox"]:checked + label {
  background: #db6977 !important;
  color: #ffffff !important;
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.selectSeasonSeat input[type="checkbox"]:disabled + label {
  background: #ddd !important;
  color: white !important;
  overflow: hidden !important;
  border: 3px solid #dddddd !important;
}
.selectSeasonSeat input[type="checkbox"]:disabled + label:after {
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.selectSeasonSeat input[type="checkbox"]:disabled + label:after {
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.selectSeasonSeat input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.selectSeasonSeat label {
  display: block;
  position: relative;
  width: 100% !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1rem;
  padding: 2px;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
  border: 3px solid #db6977 !important;
}
.selectSeasonSeat label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}
