.register-radio{
    display: flex;
    gap: 20px;
    justify-content: center;
}
.form-check-input:checked{
    background-color: #7ad254;
    border-color: #7ad254;
}
.stdropdown-container{
    background-color: #f9f9f9 !important;
}
.react-tel-input .form-control{
    height: 50px !important;
    width: 90% !important;
    margin-left: 26px !important;
}
.stsearch-box input{
    border: none !important;
    height: 40px !important;
    background-color: #f9f9f9 !important;
    font-size: 14px !important;
}
.reg-eye {
    position: absolute;
    right: 10px;
    scale: 1.2;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }