/* --- Organisation Dashboard Page --- */

/* Medium devices (desktops)
==========================*/
@media (min-width: 992px) {
  body {
    overflow-y: scroll;
  }

  .logo img {
    width: 200px;
    text-align: center;
    margin-top: 10px;
    float: none;
  }

  .wrapper-body {
    margin-left: 254px;
    transition: all 0.3s ease-out;
  }

  .wrapper__minify {
    margin-left: 80px;
  }
}

.wrapper-body {
  padding: 35px 0;
}

.d-main-title h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
}

.d-main-title i {
  font-size: 22px;
}

.add-organisation-card {
  display: flex;
  width: 100%;
  align-items: center;
}

.ocard-left {
  display: flex;
  align-items: center;
}

.ocard-avatar {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.ocard-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.ocard-name {
  margin-left: 17px;
}

.ocard-name h4 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
}

.ocard-name span {
  font-size: 14px;
  color: #717171;
}

.ocard-right {
  margin-left: auto;
}

.ocard-right .co-main-btn i {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  background: #f7f7f7;
  border: 2px dashed #bbb;
  border-radius: 50%;
  line-height: 2;
  font-size: 11px;
  color: #bbb;
}

.org_design_button {
  width: 120px;
  display: inline-block;
  min-height: 120px;
  overflow: hidden;
  border: 2px dashed #bbb;
  background: #fff;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
}

.org_design_button:hover {
  background: #e8f7f7;
}

.org_design_button span {
  vertical-align: middle;
  display: block;
  text-align: center;
  color: #bbb;
  padding: 39px 0;
}

.org_design_button i {
  margin-top: 0;
  font-size: 24px;
  color: #bbb;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  outline: 0;
  background: #fff;
  cursor: inherit;
  display: block;
}

.dashboard-date-arrows a {
  font-size: 14px;
  color: #717171;
  padding: 4px 10px 2px;
  display: inline-block;
  text-align: center;
  background: #e8f7f7;
  border-radius: 3px;
}

.dashboard-date-arrows a:hover {
  color: #1d3f73 !important;
}

.before_date.disabled,
.after_date.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.dashboard-select-date {
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 500;
}
.org-button-create {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.event-list-dropdown {
  border: 0;
  background: transparent;
  color: #000;
  font-size: 14px;
  font-family: inherit;
}

.event-list-dropdown .dropdown-item {
  padding: 2px 20px !important;
}

.dashboard-report-content {
  padding: 0 1.5rem;
}

.dashboard-report-card {
  position: relative;
  margin-bottom: 1.5rem;
  border: 1px solid #efefef;
  box-shadow: none;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.dashboard-report-card .card-content {
  color: #fff;
  flex-grow: 1;
}

.dashboard-report-card.purple {
  background-color: #8892d6;
  border-color: #8892d6;
}

.dashboard-report-card.red {
  background-color: #fc544b;
  border-color: #fc544b;
}

.dashboard-report-card.info {
  background-color: #45bbe0;
  border-color: #45bbe0;
}

.dashboard-report-card.success {
  background-color: #1d3f73;
  border-color: #78c350;
}

.dashboard-report-card .card-media {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  justify-content: center;
  right: 20px;
  top: 20px;
  position: absolute;
}

.dashboard-report-card .card-content {
  color: #fff;
  flex-grow: 1;
}

.dashboard-report-card .card-content .card-title {
  text-transform: uppercase;
  display: block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
}

.dashboard-report-card .card-content .card-sub-title {
  font-size: 24px;
  margin: 8px 0 13px;
  display: block;
  font-weight: 600;
}

.dashboard-report-card .card-media i {
  font-size: 28px;
  color: #fff;
}

.border_bottom {
  border-bottom: 1px solid #efefef;
}

.border_top {
  border-top: 1px solid #efefef;
}

.select-graphic-category
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 150px !important;
}

.rs .btn-check:active + .btn-outline-primary,
.rs .btn-check:checked + .btn-outline-primary,
.rs .btn-outline-primary.active,
.rs .btn-outline-primary.dropdown-toggle.show,
.rs .btn-outline-primary:active {
  color: #fff;
  background-color: #1d3f73;
  border-color: #1d3f73;
}

.rs .btn-outline-primary {
  color: #1d3f73;
  border-color: #1d3f73;
}

.rs .btn-outline-primary:hover {
  color: #1d3f73;
  background-color: #e8f7f7;
  border-color: #1d3f73;
}

.rs .btn-outline-primary:focus {
  box-shadow: none !important;
  outline: none !important;
}

.rs .btn-check:focus {
  box-shadow: none !important;
  outline: none !important;
}

.relative-input .form-control {
  padding: 0 15px 0 40px !important;
  width: 280px;
}

.relative-input i {
  position: absolute;
  top: 20%;
  font-size: 16px;
  left: 10px;
  color: #717171;
}

.custom2-tabs .tab-link {
  background: #fff;
  border: 1px solid #bbb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0;
  width: 130px;
  padding: 0 15px;
  font-size: 14px;
  height: 40px;
  color: #000;
  display: inline-block;
  margin: 0 0 0 4px !important;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.custom2-tabs .tab-link:first-child {
  margin-left: 0 !important;
}

.custom2-tabs .tab-link.active,
.tab-tabs .show > .tab-link {
  color: #fff;
  background-color: #1d3f73;
  border: 1px solid #1d3f73;
}

.custom2-tabs .tab-link:hover {
  color: #000;
  background-color: #e8f7f7;
  border: 1px solid #bbb;
}

.custom2-tabs .tab-link.active span,
.tab-tabs .show > .tab-link span {
  color: #fff;
}

.custom2-tabs .tab-link.active:hover {
  color: #fff;
  background-color: #1d3f73;
  border: 1px solid #1d3f73;
}

.event-top .card-event-img {
  width: 130px;
  height: 70px;
}

.option-btn {
  /* width: 35px;
  height: 35px; */
  color: #000;
  border: 1px solid #bbb;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.option-btn:hover {
  color: #1d3f73;
  border: 1px solid #1d3f73;
  background: rgba(255, 255, 255, 0.3);
}

.event-list-icon {
  width: 110px;
  height: 110px;
  background: #fafafa;
  border: 1px solid #efefef;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  padding-top: 30px;
}

.event-list-icon img {
  width: 80px;
}

.contact-list {
  box-sizing: border-box;
}

.contact-list .top .icon-box {
  position: relative;
  padding-left: 66px;
  width: calc(100% - 150px);
}

.contact-list .top .icon-box .icon-big {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #d4f7f7;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 0;
}

.contact-list .top .icon-box .icon {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #d4f7f7;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 2px;
}

.icon.icon-purple {
  background-color: rgba(155, 81, 224, 0.1) !important;
}

.contact-list .top .icon-box h5 {
  font-size: 18px;
  margin: 0;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.contact-list .top .icon-box p {
  font-size: 13px;
  margin: 0 !important;
  color: #717171;
  word-break: break-all;
}

.option-btn-1 {
  width: 35px;
  height: 35px;
  color: #000;
  border: 1px solid #bbb;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.option-btn-1:hover {
  color: #1d3f73;
  border: 1px solid #1d3f73;
  background: rgba(255, 255, 255, 0.3);
}

.icon-box .icon {
  font-size: 18px;
  margin-right: 7px;
  padding-top: 2px;
}

.rotate-icon {
  transform: rotate(300deg);
}

.contact-list .bottom .icon-box {
  min-width: 130px;
  position: relative;
  padding-left: 52px;
  width: 25%;
  max-width: 100%;
}

.card-icon-align.bottom .icon-box {
  padding-left: 60px !important;
}

.contact-list .bottom .icon-box .icon {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: #f5f7fd;
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

.contact-list .bottom .icon-box h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  margin: 0 0 6px;
}

.contact-list .bottom .icon-box p {
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #717171;
  margin: 0;
}

.contact-list .bottom .icon-box:not(:last-child) {
  padding-right: 15px;
}

.contact-list .bottom .icon-box .progress {
  background: rgba(163, 173, 185, 0.3);
  border-radius: 2px;
  height: 4px;
}

.contact-list .bottom .icon-box .progress {
  max-width: 80px;
}

.contact-list .bottom .icon-box .progress .progress-bar {
  background: #1d3f73;
  border-radius: 2px;
}

.gift-coupon-icon {
  width: 100%;
  text-align: center;
  padding-bottom: 0;
  border-bottom: 2px dashed #efefef;
  margin-bottom: 10px;
}

.gift-coupon-icon img {
  width: 100px;
  height: 100px;
}

.datepickers-container {
  z-index: 9999;
}

.datepicker--day-name {
  color: #1d3f73 !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
}

.icon.icon-purple {
  color: #6b63ff;
  background-color: rgba(155, 81, 224, 0.1) !important;
}

.icon.icon-yellow {
  color: #e8df2a;
  background-color: rgba(242, 153, 74, 0.1) !important;
}

.bank-card {
  position: relative;
  border: 1px solid #efefef;
  background: #f9f9f9;
  border-radius: 3px;
}

.bank-card h5 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.bank-card h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #000;
}

.bank-card span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #717171;
}

.action-link {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  color: #717171;
  border: 1px solid #bbb;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
}

.action-link:hover {
  color: #1d3f73 !important;
  border: 1px solid #1d3f73;
  background: rgba(255, 255, 255, 0.3);
}

.rmt_4 {
  margin-top: 1.5rem;
}

.table-card {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
}

.main-table .table {
  color: #000;
  margin-bottom: 0;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
}

.main-table .table > :not(:first-child) {
  border-top: 1px solid #efefef;
}

.main-table .table .thead-dark th {
  color: #000;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  border-bottom: 0;
  border: 1px;
  font-size: 14px;
  /* font-weight: 500; */
}

.main-table .table .thead-dark th:first-child {
  border-top-left-radius: 3px;
}

.main-table .table .thead-dark th:last-child {
  border-top-right-radius: 3px;
}

.main-table .table .thead-dark tr {
  border-radius: 3px;
}

.main-table .table .thead-dark tr th:first-child {
  border-top-left-radius: 3px;
}

.main-table .table .thead-dark tr th:last-child {
  border-top-right-radius: 3px;
}

.main-table .table tbody tr td a {
  color: #000;
}

.discount-table .table td,
.discount-table .table th {
  padding: 1rem 1rem !important;
  border-top: 1px solid transparent !important;
}

.main-table .table td,
.main-table .table th {
  padding: 1rem 1rem !important;
  border-top: 1px solid transparent !important;
}

.main-table .table tfoot {
  font-weight: 500;
  padding: 14px !important;
  color: #000 !important;
}

.main-table .table tfoot td {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  background-color: #fafafa;
}

.main-table .table td {
  vertical-align: middle;
  padding: 12px 10px 9px;
  font-size: 14px;
}

.status-circle {
  background-color: #efefef;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

.red-circle {
  background-color: #dc3545;
}

.green-circle {
  background-color: #7dc579;
}

.blue-circle {
  background-color: #bb6bd9;
}

.a-link {
  color: #1d3f73 !important;
  font-weight: 500;
}

.a-link:hover {
  color: #1d3f73 !important;
  text-decoration: underline !important;
}

.subscription-bg {
  margin-bottom: 16px;
  padding: 28px;
  min-height: 170px;
  position: relative;
}

.s-info h3 {
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

.s-info p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}

.s-price h3 {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.since-text {
  font-style: italic;
  color: #fff;
}

.subscription-title h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
}

.subscription-item {
  border: 1px solid #efefef;
  border-radius: 3px;
}

.subscription-item p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bp-info h4 {
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
}

.btn-gray {
  padding: 8.5px 16px;
  border-radius: 5px;
  font-weight: bold;
  border: 0;
  background: #f5f5f5;
  color: rgba(33, 33, 33, 0.6);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.btn-gray:hover.no-bg:hover svg path {
  fill: #22b0af;
}

.no-bg {
  background: none !important;
  background-color: transparent !important;
}

.c-img {
  width: 60px;
  margin-bottom: 5px;
}

.tracking-tab .tracking-head {
  background: #fff;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #efefef;
  margin-top: 1.5rem;
}

.tracking-tab .nav-tabs .nav-item {
  margin-bottom: 0px;
  width: 33.333%;
}

.tracking-tab .nav-tabs .nav-link {
  display: block;
  text-align: center;
  background: #fff;
  border: 0;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 0rem 1.3rem;
}

.tracking-tab .nav-tabs .nav-link.active {
  background-color: #1d3f73;
  color: #fff !important;
}

.specific-event {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #efefef;
}

.specific-event h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #000;
}

.organisation-profile {
  position: relative;
  padding-top: 50px;
  max-width: 560px;
  min-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.view-profile-link {
  display: inline-block;
}

.profile-edit-btn {
  position: absolute;
  right: 10px;
  top: 15px;
}

.profile-edit-btn .btn {
  color: #717171;
  font-size: 16px;
}

.profile-edit-btn .btn:hover {
  color: #1d3f73 !important;
}

.profile-edit-btn .btn:focus {
  box-shadow: none;
}

.p-bg {
  background-color: #f5f7f9;
}

.copy-link {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
  display: inline-block;
  line-height: 36px;
  padding: 0 10px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.team-lock {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 15px;
  width: 170%;
  margin-top: -33px;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  color: grey;
}

.team-lock:after {
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  float: right;
  padding-right: 30px;
  font-size: 12px;
  color: #717171;
  padding-top: 1px;
}

.team-lock > .menu--label {
  display: inline-block;
  height: 45px;
  line-height: 43px;
}

.team-lock > .menu--label:hover {
  background: #e8f7f7;
}

.upgrade-premium-area {
  justify-content: center;
  display: flex;
  padding: 30px;
}

.upgrade-content .upgrade-premium-center {
  max-width: 556px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 2px 4px 7px rgb(0 0 0 / 7%);
  border-radius: 5px;
  text-align: center;
  padding: 75px 30px 30px 30px;
  background-repeat: no-repeat;
  background-position: 30% 1%;
  overflow-y: auto;
}

.upgrade-icon img {
  width: 100px;
}

.upgrade-banner img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.upgrade-text h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 22px 0 13px;
  color: #000;
}

.upgrade-text p {
  font-size: 16px;
  font-weight: 400;
  color: #717171;
}

.premium-select {
  margin-top: 20px;
}

.premium-select .option {
  margin-top: 1.5rem;
  text-align: center;
  display: block;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  padding: 40px;
  border: 2px solid #efefef;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.premium-select .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.premium-select .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

.premium-select input[type="radio"] {
  display: none;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border: 2px solid #1d3f73;
  background: #fff;
}

#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #1d3f73;
}

#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.wrapper .option span {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  display: block;
  margin: 13px 0 10px;
}

.wrapper .option span:last-child {
  font-size: 16px;
  font-weight: 400;
  color: #717171;
  display: block;
  margin-bottom: 0;
}

#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #000;
}

#option-1:checked:checked ~ .option-1 span:last-child,
#option-2:checked:checked ~ .option-2 span:last-child {
  color: #717171;
  display: block;
}

.action-btn {
  cursor: pointer;
}

.action-btn:hover {
  color: #1d3f73;
}

.disabled {
  pointer-events: none !important;
}

.role-header {
  background: #efefef;
  text-align: left;
  padding: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.role-header h6 {
  font-size: 18px;
  font-weight: 500;
}

.role-body-content {
  padding: 25px;
}

.role-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.role-item:last-child {
  margin-bottom: 0;
}

.role-item i {
  color: #1d3f73;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(106, 192, 69, 0.1);
  border-radius: 50%;
  display: inline-block;
  font-size: 14px;
}

.i-disabled {
  color: #000 !important;
  background: rgba(0, 0, 0, 0.04) !important;
}

.role-item span {
  color: #000;
  font-weight: 400;
  margin-left: 7px;
}

.mt-30 {
  margin-top: 30px;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-org {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.camera-icon {
  height: 30px;
  width: 30px;
}
.main-btn-org-popup {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  background: #1d3f73;
  height: 40px;
  display: inline-block;
  padding: 0 29px;
  border-radius: 3px;
  border: 1px solid #1d3f73;
}
.select-org {
  width: 80%;
  padding: 12px;
  font-size: 15px;
  border: 1px solid grey;
  border-radius: 3px;
}
.left-arrow {
  color: #000;
  height: 17px;
  width: 15px;
}
.left-arrow:hover {
  color: #1d3f73;
}
.moneybill-icon {
  height: 33px;
  width: 33px;
}
.search--icon {
  height: 20px;
  width: 20px;
}
.edit-icon-payout {
  color: #818181;
  height: 20px;
  width: 20px;
}
.edit-icon-payout:hover {
  color: #1d3f73;
}
.delet-icon-payout {
  color: #818181;
  height: 17px;
  width: 17px;
}
.delet-icon-payout:hover {
  color: #1d3f73;
}
.font-sty-event {
  font-size: 1.25rem;
}
.location-icon {
  height: 20px;
  width: 20px;
}
.duplicate-icon {
  height: 20px;
  width: 20px;
}
.eye-icon-sty {
  height: 15px;
  width: 20px;
}
.event-count-sty {
  width: 145px !important;
}
.selectpicker-add-org {
  position: absolute !important;
  display: block !important;
  height: 3% !important;
  width: 45%;
  -ms-flex-pack: justify;
  background: #f9f9f9;
  border-radius: 3px;
  padding-left: 14px;
  border: 1px solid #efefef;
  font-size: 14px;
}
.selectpicker-add-orgReport {
  position: absolute !important;
  display: block !important;
  height: 22%;
  width: 20%;
  -ms-flex-pack: justify;
  background: transparent;
  border-radius: 3px;
  padding-left: 14px;
  border: 1px solid #efefef;
  font-size: 14px;
}
.card-event-img-report img {
  width: 124%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
  margin-left: 40px;
}
.view-report {
  width: 221px;
  display: block !important;
}
.viewreport-btn {
  background: #1d3f73;
  border: 1px solid #bbb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0;
  width: 130px;
  padding: 0 15px;
  font-size: 14px;
  height: 40px;
  color: #fff;
  display: inline-block;
  margin: 0 0 0 4px !important;
  transition: all 0.5s ease 0s;
}
.viewreport-btn:hover {
  color: #fff !important;
  background: #265399;
  border: 1px solid #265399;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ocard-left {
    display: block !important;
    align-items: center;
  }
}
@media (max-width: 575.98px) {
  .ocard-left {
    display: block !important;
    align-items: center;
  }
  .co-main-btn-responsive {
    font-size: 14px !important;
    margin-left: -1px !important;
  }
}
.co-main-btn-responsive {
  padding-right: 8px;
}

/* Orgnaization List */
.DeactiveBtn {
  border-radius: 5px;
  padding: 7px;
  background-color: #fdecec;
  color: #7a0202;
  border: none;
  height: 37px;
  width: 90px;
  font-size: 16px;
}
.ActiveBtn {
  border-radius: 5px;
  padding: 7px;
  background-color: #d2f4e0;
  color: #207a48;
  border: none;
  height: 37px;
  width: 90px;
  font-size: 16px;
}
.cxFtRn {
  background-color: transparent !important;
}
.lcunyj svg {
  right: -3px !important;
}
.finishbtn {
  padding: 0 1px !important;
}

/* Org My Team */
.modal-org-margin {
  margin: 0rem !important;
}
.modal-myteam {
  background-color: #fff;
  height: 50%;
  margin-top: 6%;
  margin-left: 35%;
  display: block;
  border-radius: 7px;
}
.verify-success-icon {
  height: 120px;
  width: 120px;
  color: #58e195;
}
.checkin-Btn {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #1d3f73;
  height: 37px;
  display: inline-block;
  line-height: 36px;
  padding: 0 30px;
  border-radius: 3px;
  border: 1px solid #1d3f73;
}
.checkin-Btn:disabled {
  background-color: #717171;
  color: #000;
  cursor: not-allowed;
}
/* Org About */
.orgabout-icon {
  color: #1d3f73;
  scale: 1.3;
}
.popup-about {
  position: fixed;
  top: 15px;
  left: 0;
  z-index: 1055;
  display: none;
  width: 40%;
  height: 95%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding-right: 0px !important;
  margin-top: 0px;
  margin-left: 30%;
}
.modal-dialog-scrollable .modal-content {
  max-height: 108%;
  overflow: hidden;
}
.orgaboutselect {
  padding: 0px !important;
  height: 35px !important;
}
.camera-icon-sty {
  left: 57%;
}
.acceptedsty {
  color: green;
}
.pendingsty {
  color: #ff5100;
}
.admin-list-css {
  list-style: none;
}
.cancelBtnDuplicate {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #e76161;
  height: 50px;
  display: inline-block;
  line-height: 36px;
  padding: 0 30px;
  border-radius: 3px;
  border: 1px solid #e76161;
}
