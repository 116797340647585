.termsbox {
  background-color: white;
  width: 90%;
  /* margin-top: 3%; */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  text-align: justify;
  padding: 2%;
  padding-left: 3%;
  padding-right: 3%;
  text-align: justify;
}
.termsheadbox {
  background-color: #1D3F73;
}
.privacy-text {
  font-size: 25px;
  font-weight: 600;
}
.privacy-body-text {
  font-size: large;
  font-weight: 600;
}
