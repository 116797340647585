/* Load Icon */
.load-icon {
	width: 100px;
	height: 20px;
      position: absolute;
  margin: auto;
  right: 0;
  left: 0;
}
.load-icon span {
	position: absolute;
	display: inline-block;
	background: #222;
	width: 20%;
	height: 100%;
	border-radius: 100px;
}
.load-icon span:nth-of-type(1) {
	left: 0;
}
.load-icon span:nth-of-type(2) {
	left: 40%;
}
.load-icon span:nth-of-type(3) {
	right: 0;
}

/* Animation */
.load-icon span {
	animation-name: loading;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
}
.load-icon span:nth-of-type(2) {
	animation-delay: .2s;
}
.load-icon span:nth-of-type(3) {
	animation-delay: .4s;
}

/* Keyframes */
@keyframes loading {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

/* This isn't needed, just styling pen. */

