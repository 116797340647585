.header-wrapper {
  background: #fff;
  position: fixed;
  width: 100%;
  box-shadow: 0px 0.052083333in 11.25pt -9px rgb(0 0 0 / 1%);
}

.header {
  position: relative;
  width: 100%;
}

.logo-inverse {
  display: none;
}

.main-logo img {
  padding-top: 0;
  height: 61px;
  width: 78px;
}

.main-logo {
  width: 120px;
}
.show-main-logo {
  margin-left: 80px;
}
.res-main-logo {
  display: none;
}

#night-mode {
  padding: 0;
  font-size: 15px;
  background: #e8f7f7;
  color: #717171;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.bg-barren {
  background-color: #ffffff;
  box-shadow: 0px 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
}

.barren-head {
  height: 75px;
}

.navbar-toggler {
  color: #000;
  padding: 0.25rem 1rem 0.25rem 0 !important;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none !important;
}

.navbar-toggler-icon {
  height: auto !important;
}

.navbar-toggler-icon:hover {
  color: #1d3f73 !important;
}

.create-btn i {
  margin-right: 10px;
}

.create-btn {
  font-size: 14px;
  font-weight: 400;
  color: #fff !important;
  height: 40px;
  display: inline-block;
  border: 2px solid #1d3f73;
  background-color: #1d3f73;
  padding: 8px 18px;
  border-radius: 3px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
}
.create-btn:hover {
  color: white !important;
}
.right-header ul li {
  display: inline-block;
  vertical-align: bottom;
}

.right-header ul li:first-child {
  margin-right: 25px;
}

.right-header ul li:last-child {
  margin-left: 22px;
}

.account-link {
  color: #1d1d1d;
}

.arrow-icon {
  margin-left: 5px;
}

.account-link img {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #efefef;
  padding: 2px;
  object-fit: cover;
}

.account-link.show img {
  border: 2px solid #1d3f73;
}

.dropdown-menu-account li {
  margin: 0 !important;
}

.dropdown-menu-account {
  padding: 0 !important;
  width: 250px;
  margin-top: 9px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  border: 1px solid #f1f1f1 !important;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.dropdown-menu-account li {
  margin: 0 !important;
  display: block !important;
}

.dropdown-account-header {
  text-align: center;
  padding: 20px;
}

.account-holder-avatar img {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #1d3f73;
  padding: 2px;
  object-fit: cover;
}

.dropdown-account-header h5 {
  font-size: 16px;
  margin-top: 13px;
  margin-bottom: 3px;
}

.dropdown-account-header p {
  display: block;
  color: #686868;
  font-size: 13px;
  margin-bottom: 0;
}

.profile-link {
  border-top: 1px solid #efefef;
  padding: 10px 0;
}

.dropdown-menu-account li .link-item {
  display: block;
  width: 100%;
  color: #000000 !important;
  padding: 10px 20px;
}

.dropdown-menu-account li .link-item:hover {
  color: #1d3f73 !important;
  background-color: #e8f7f7;
}

.nav-link {
  color: #000 !important;
}

.nav-link:hover {
  color: #1d3f73 !important;
}

.barren-head .nav-item .nav-link.active,
.barren-head .nav-item .show > .nav-link {
  color: #1d3f73 !important;
}
.lang-select-input {
  padding: 0rem 0.5rem;
  background-color: #7895c2;
  color: white;
  border: none;
  width: 95px;
}
.lang-select-box {
  width: 120px;
  height: 32px;
  background-color: #7895c2;
  color: white;
  display: flex;
  border-radius: 5px;
}
.lang-select-box span {
  margin-top: 5px;
  margin-inline-start: 5px;
}
.right-header {
  height: 50px;
}
.Login-btn {
  height: 32px;
  width: 150%;
  max-width: 120px;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
}
.right-header .align-self-stretch .account-dropdown {
  margin-top: 3px;
}
.close-btn {
  padding: 6px;
  font-size: 20px;
  background: #f1f2f3;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  width: 40px;
  height: 40px;
  border: 0;
}

.close-btn:hover,
.close-btn:focus {
  background: #f1f2f3;
  color: rgba(0, 0, 0, 1);
}

.offcanvas-top-area {
  display: none;
}

.offcanvas-create-btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
  background-color: #1d3f73;
  border: 2px solid #1d3f73;
  display: block;
  height: 40px;
  padding: 7px;
  border-radius: 3px;
}

.offcanvas-create-btn i {
  margin-right: 10px;
}

.offcanvas-create-btn:hover {
  color: #fff !important;
  background: #265399;
  border: 2px solid #265399;
}

.offcanvas-footer {
  border-top: 1px solid #efefef;
}

.offcanvas-social {
  padding: 30px 20px;
}

.offcanvas-social > h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
}

.offcanvas-social > .social-links > li {
  display: inline-block;
  margin-right: 10px;
}

.offcanvas-social > .social-links > li:last-child {
  margin-right: 0;
}

.offcanvas-social > .social-links li > .social-link {
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: block;
  border: 2px solid #1d3f73;
  border-radius: 100%;
  text-align: center;
  line-height: 38px;
  color: #1d3f73;
}

.offcanvas-social > .social-links li > .social-link:hover {
  color: #fff !important;
  border: 2px solid #1d3f73;
  background-color: #1d3f73;
}

.dropdown-submenu {
  /* width: 230px; */
  margin-top: 13px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  border: 1px solid #f1f1f1 !important;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.dropdown-submenu .dropdown-item {
  display: block;
  font-size: 14px;
  width: 100%;
  color: #000000;
  padding: 10px 20px;
}

.dropdown-submenu .dropdown-item:hover {
  color: #1d3f73 !important;
  background-color: #e8f7f7;
}

.dropdown-submenu .dropdown-item:focus {
  color: #1d3f73 !important;
  background-color: transparent;
}

/*  */

.pe_5 {
  padding-right: 37px !important;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-item {
  display: block !important;
  font-size: 14px !important;
  width: 100% !important;
  color: #000000 !important;
  font-weight: 400 !important;
  padding: 10px 20px !important;
  margin-top: 0 !important;
}
.right-arrow {
  margin-left: 80px;
  width: 30px;
  height: 10px;
}
.header-font {
  font-size: 1rem;
}
.calendericon {
  width: 20px;
  height: 20px;
}
.downarrow {
  color: #000;
}

.modal {
  width: 29%;
  background-color: #fff;
  height: 20%;
  margin-top: 20%;
  margin-left: 36%;
  display: block;
  border-radius: 7px;
}
.signout-text {
  font-size: 20px;
}
.popup_btn_yes {
  font-size: 20px;
  font-weight: 400;
  color: #fff !important;
  height: 40px;
  display: inline-block;
  border: 2px solid #1d3f73;
  background-color: #1d3f73;
  padding: 3px 18px;
  border-radius: 3px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
}
.popup_btn_yes:hover {
  background-color: transparent;
  border: 2px solid #1d3f73;
  color: #000 !important;
}
.popup_btn_No {
  font-size: 20px;
  font-weight: 400;
  color: #fff !important;
  height: 40px;
  display: inline-block;
  border: none;
  background-color: rgba(255, 2, 52, 0.616);
  padding: 3px 18px;
  border-radius: 3px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
  width: 100px;
}
.popup_btn_No:hover {
  background-color: transparent;
  border: 2px solid rgba(255, 2, 52, 0.616);
  color: #000 !important;
}
.close {
  margin-top: 17px;
  margin-left: 89%;
  cursor: pointer;
}
#active1:hover {
  background-color: transparent;
}
#active1 {
  text-decoration: underline;
  text-decoration-color: #265399 !important;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #265399 !important;
}
#activeCreateEvent {
  font-size: 17px;
  font-weight: 400;
  color: #000 !important;
  height: 40px;
  display: inline-block;
  border: 2px solid #1d3f73;
  background-color: transparent;
  padding: 5px 17px;
  border-radius: 3px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
}
.signoutbtn-haeder {
  border: none;
  width: 97px !important;
  background: transparent;
  margin-left: -18px;
  color: #000;
}
.explore-icon {
  height: 20px;
  width: 20px;
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (max-width: 575.98px) {
  .create-btn {
    padding: 5px 10px;
    display: none;
  }
  .Login-btn-mob {
    display: flex !important;
  }
  .Login-btn-mob span {
    display: flex !important;
  }
  .res-main-logo {
    display: inline-block;
  }
  .signout-modal {
    height: 171px;
    margin-top: 243px;
    width: 92%;
    margin-left: 15px;
  }
}
.toggle_menu i {
  width: 50px;
  text-align: center;
  height: 57px;
  display: block;
  padding: 0;
  border: 0;
  background: #fff;
  color: #737373;
  font-size: 120%;
  margin: 0;
  cursor: pointer;
  float: left;
}
@media (min-width: 992px) {
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
  .calendericon {
    margin-left: -15px !important;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-top-area {
    display: block;
    padding: 0 0 20px;
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .create-btn-res {
    padding: 8px 3px !important;
  }
}
.closeIconSty {
  height: 25px;
  width: 25px;
}
.team-dropdown {
  height: max-content;
  overflow-y: scroll;
}
.sigin-text{
  margin-left: 5px;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  cursor: pointer !important;
  padding: 5px !important;
}
.css-ohyacs {
  cursor: pointer !important;
  padding: 5px !important;
}