/*
=========================
Vertical Reponsive Menu
=========================
*/
/* line 6, sass/import/mixins.scss */
.vertical_nav,
.collapse_menu .collapse_menu--icon {
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* line 14, sass/vertical-responsive-menu.scss */
* {
  box-sizing: border-box;
}

/* line 26, sass/vertical-responsive-menu.scss */
:focus,
:active {
  outline: none;
}

/* line 32, sass/vertical-responsive-menu.scss */
.toggle_menu {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  background: none;
  border: 0;
  text-align: center;
  cursor: pointer;
}
/* line 44, sass/vertical-responsive-menu.scss */
.toggle_menu i {
  display: block;
  font-size: 28px;
}

/* line 52, sass/vertical-responsive-menu.scss */
.vertical_nav {
  position: absolute;
  left: -200px;
  top: 50px;
  bottom: 0px;
  width: 200px;
  background: #f6f6f6;
}

/* line 63, sass/vertical-responsive-menu.scss */
.vertical_nav__closed {
  left: -200px !important;
}

/* line 67, sass/vertical-responsive-menu.scss */
.vertical_nav__opened {
  left: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.4) -30px 0 30px 30px;
}

/* line 73, sass/vertical-responsive-menu.scss */
.menu {
  position: absolute;
  top: 20px;
  bottom: 40px;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* line 84, sass/vertical-responsive-menu.scss */
.menu--item {
  position: relative;
  min-height: 40px;
  line-height: 40px;
}

/* line 90, sass/vertical-responsive-menu.scss */
.menu--item__has_sub_menu .menu--link:after {
  position: absolute;
  top: 0;
  right: 15px;
  height: 40px;
  line-height: 40px;
  font-family: "FontAwesome";
  content: "\f0d7";
}

/* line 100, sass/vertical-responsive-menu.scss */
.menu--subitens__opened {
  background: #e9e9e9;
}

/* line 103, sass/vertical-responsive-menu.scss */
.menu--subitens__opened .menu--link {
  color: #000;
}

/* line 107, sass/vertical-responsive-menu.scss */
.menu--subitens__opened .sub_menu {
  display: block;
}

/* line 113, sass/vertical-responsive-menu.scss */
.menu--link {
  display: block;
  overflow: hidden;
  font-size: 0.875rem;
  text-decoration: none;
  color: #666;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}
span [title]:hover::after {
  content: attr(title);
  padding: 40px 8px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  background: red;
}
/* line 123, sass/vertical-responsive-menu.scss */
.menu--link:hover,
.menu--link:hover .menu--label {
  background: #dddddd;
}

/* line 131, sass/vertical-responsive-menu.scss */
.menu--icon {
  display: block;
  float: left;
  width: 55px;
  height: 18px;
  line-height: 28px !important;
  font-size: 14px;
  text-align: center;
  margin-top: 13px;
}

/* line 140, sass/vertical-responsive-menu.scss */
.menu--label {
  display: block;
  height: 40px;
  line-height: 40px;
}

/* line 148, sass/vertical-responsive-menu.scss */
.sub_menu {
  display: none;
  overflow: hidden;
  padding-left: 0;
  list-style: none;
}

/* line 154, sass/vertical-responsive-menu.scss */
.sub_menu--link {
  display: block;
  padding-left: 50px;
  padding-right: 16px;
  font-size: 0.875rem;
  color: #666;
  text-decoration: none;
}

/* line 162, sass/vertical-responsive-menu.scss */
.sub_menu--link:hover {
  color: #000;
  background: #dddddd;
}

/* line 168, sass/vertical-responsive-menu.scss */
.sub_menu--link__active {
  color: #000;
  font-weight: 500;
}

/* line 176, sass/vertical-responsive-menu.scss */
.collapse_menu {
  display: none;
}
.teamActiveSty {
  background-color: #ecfdf3;
  color: #207a48;
  border-radius: 5px;
  border: 1px solid #207a48;
  font-size: x-small;
  padding: 2px;
}
.teamDeactiveSty {
  background-color: #fdecec;
  color: #7a0202;
  border-radius: 5px;
  border: 1px solid #7a0202;
  font-size: x-small;
  padding: 2px;
}
.resendBtnSty {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #1d3f73;
  height: 30px;
  display: inline-block;
  padding: 0 20px;
  border-radius: 3px;
  border: 1px solid #1d3f73;
}
.removeAdminBtn {
  background-color: #e76161;
  color: white;
  border: 1px solid #e76161;
  padding: 8px 10px;
  border-radius: 4px;
  margin-inline-start: 10px;
  height: 35px;
}
.removeAdminBtn:hover {
  color: #e76161;
  border: 1px solid #e76161;
  background-color: white;
  cursor: pointer;
}
.removeAdminBtn:disabled {
  cursor: not-allowed;
}
@media (min-width: 576px) and (max-width: 767.98px) {
}
@media (min-width: 992px) {
  /* line 193, sass/vertical-responsive-menu.scss */
  .vertical_nav {
    left: 0;
  }

  /* line 197, sass/vertical-responsive-menu.scss */
  .toggle_menu {
    display: none;
  }

  /* line 203, sass/vertical-responsive-menu.scss */
  .vertical_nav__minify {
    width: 50px;
  }

  /* line 206, sass/vertical-responsive-menu.scss */
  .vertical_nav__minify .menu {
    overflow: visible;
  }

  /* line 210, sass/vertical-responsive-menu.scss */
  .vertical_nav__minify .menu--label {
    display: none;
    position: absolute;
    top: 0;
    left: 50px;
    width: 150px;
    padding-left: 16px;
    padding-right: 16px;
    background: #e9e9e9;
    font-weight: 500;
  }

  /* line 222, sass/vertical-responsive-menu.scss */
  .vertical_nav__minify .menu--item__has_sub_menu .menu--link:after {
    content: "";
  }

  /* line 228, sass/vertical-responsive-menu.scss */
  .vertical_nav__minify .menu--subitens__opened .menu--label,
  .vertical_nav__minify .menu--subitens__opened .sub_menu {
    display: block;
  }

  /* line 232, sass/vertical-responsive-menu.scss */
  .vertical_nav__minify .sub_menu {
    position: absolute;
    top: 40px;
    left: 50px;
    width: 150px;
    background: #e9e9e9;
  }

  /* line 239, sass/vertical-responsive-menu.scss */
  .vertical_nav__minify .sub_menu--link {
    padding-left: 16px;
  }

  /* line 245, sass/vertical-responsive-menu.scss */
  .vertical_nav__minify .collapse_menu--icon {
    -webkit-transform: rotate(180deg);
  }

  /* line 253, sass/vertical-responsive-menu.scss */
  .collapse_menu {
    position: absolute;
    bottom: 0px;
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0;
    border: 0;
    border-top: 1px solid #e6e6e6;
    background: #f6f6f6;
    color: #666;
    font-size: 0.875rem;
    text-align: left;
    cursor: pointer;
  }

  /* line 269, sass/vertical-responsive-menu.scss */
  .collapse_menu:hover {
    color: #000;
    background: #dddddd;
  }

  /* line 274, sass/vertical-responsive-menu.scss */
  .collapse_menu .collapse_menu--icon {
    display: block;
    float: left;
    width: 50px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }

  /* line 288, sass/vertical-responsive-menu.scss */
  .collapse_menu .collapse_menu--label {
    display: block;
    height: 40px;
    line-height: 40px;
  }
}
.collapse_menu .collapse_menu--icon,
.vertical_nav {
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
* {
  box-sizing: border-box;
}
:active,
:focus {
  outline: 0;
}

.toggle_menu {
  display: block;
  float: left;
  line-height: 60px;
  padding: 0;
  background: 0 0;
  border: 0;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.toggle_menu i {
  width: 50px;
  text-align: center;
  display: block;
  padding: 0;
  border: 0;
  background: #fff;
  color: #737373;
  font-size: 120%;
  margin: 0;
  cursor: pointer;
  float: left;
}

.toggle_menu i:hover {
  color: #1d3f73;
}

.vertical_nav {
  position: fixed;
  left: -240px;
  top: 75px;
  bottom: 0;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 995;
}

.vertical_nav__closed {
  left: -240px !important;
}

.vertical_nav__opened {
  left: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.2)-30px 0 30px 30px;
}

.menu_left {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.menu--item {
  position: relative;
  min-height: 42px;
  line-height: 42px;
}

.menu--link:hover {
  background: #e8f7f7;
  color: #1d3f73 !important;
}

.menu--link.active {
  background: #e8f7f7;
  color: #1d3f73;
}

.menu--item__has_sub_menu .menu--link:after {
  position: absolute;
  top: 0;
  right: 15px;
  height: 45px;
  line-height: 45px;
  font-family: FontAwesome;
  content: "\f0d7";
}

.menu--subitens__opened {
  background: #e8f7f7;
}

.menu--subitens__opened .menu--link {
  color: #333;
}

.menu--subitens__opened .sub_menu {
  display: block;
}

.menu--link {
  display: block;
  overflow: hidden;
  font-size: 14px;
  text-decoration: none;
  color: #000 !important;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}

.menu--link:hover,
.menu--link:hover .menu--label {
  background: #e8f7f7;
}

.menu--icon {
  display: block;
  float: left;
  width: 55px;
  height: 18px;
  line-height: 28px !important;
  font-size: 14px;
  text-align: center;
  margin-top: 13px;
}

.menu--label {
  display: block;
  height: 45px;
  line-height: 43px;
}

.sub_menu {
  display: none;
  overflow: hidden;
  padding-left: 0;
  list-style: none;
}

.sub_menu--link {
  display: block;
  padding-left: 50px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  text-decoration: none;
}

.sub_menu--link:hover {
  color: #1d3f73 !important;
  background: #e8f7f7;
}

.sub_menu--link.active {
  color: #da1f27;
  font-weight: 500;
}

.collapse_menu {
  display: none;
}

@media (min-width: 992px) {
  .vertical_nav {
    left: 0;
  }

  .toggle_menu {
    display: none;
  }

  .vertical_nav__minify {
    width: 60px;
  }

  .vertical_nav__minify .menu {
    overflow: visible;
  }

  .vertical_nav__minify .menu--label {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    padding-left: 16px;
    padding-right: 16px;
    background: #e9e9e9;
    font-weight: 500;
  }

  .vertical_nav__minify .menu--item__has_sub_menu .menu--link:after {
    content: "";
  }

  .vertical_nav__minify .menu--subitens__opened .menu--label,
  .vertical_nav__minify .menu--subitens__opened .sub_menu {
    display: block;
  }

  .vertical_nav__minify .sub_menu {
    position: absolute;
    top: 40px;
    left: 50px;
    width: 150px;
    background: #e9e9e9;
  }

  .vertical_nav__minify .sub_menu--link {
    padding-left: 16px;
  }

  .vertical_nav__minify .collapse_menu--icon {
    -webkit-transform: rotate(180deg);
  }

  .collapse_menu {
    width: 60px;
    text-align: center;
    display: block;
    height: 75px;
    padding: 0;
    border: 0;
    background: #1d3f73;
    color: #fff;
    font-size: 30px;
    margin: 0;
    cursor: pointer;
    float: left;
  }

  .collapse_menu:hover {
    color: #fff;
    background: #265399;
  }

  .collapse_menu .collapse_menu--icon {
    display: block;
    float: left;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
  }

  .collapse_menu .collapse_menu--label {
    display: block;
    height: 40px;
    line-height: 40px;
  }
}
#active-sidebar {
  text-decoration: underline;
  text-decoration-color: #265399 !important;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
  font-size: 15px;
  font-weight: 500;
  color: #265399 !important;
  background-color: #e8f7f7;
}
