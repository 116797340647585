.checkout-title {
  border-bottom: 2px solid #efefef;
  padding-bottom: 25px;
}

.bp-title h4 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  padding: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #efefef;
}

.bp-content {
  padding: 0 30px 30px;
}

.bp-form .form-control {
  background-color: #f9f9f9 !important;
}

.bp-form .btn-light {
  display: block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #717171 !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #efefef !important;
  padding: 5px 20px !important;
  min-height: 50px !important;
  border-radius: 3px !important;
  transition: none !important;
}

.checkout-block {
  margin-top: 40px;
}

.order-summary {
  margin-top: 40px;
}

.order-summary-content {
}

.event-order-dt {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.event-thumbnail-img img {
  object-fit: cover;
  width: 110px;
  height: 100px;
  border-radius: 5px;
}
.new-event-thumbnail-img img {
  object-fit: cover;
  width: 350px;
  height: 150px;
  border-radius: 5px;
}
.new-event-order-dt-content {
  margin-left: 0px !important;
}

.event-order-dt-content {
  margin-left: 20px;
}

.event-order-dt-content h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  color: #000;
}

.event-order-dt-content span {
  font-size: 14px;
  font-weight: 400;
  color: #717171;
  display: block;
  margin-bottom: 10px;
}

.event-order-dt-content .category-type {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  font-family: inherit;
}

.order-total-block {
  margin-top: 30px;
}

.order-total-dt {
  display: flex;
  width: 100%;
  align-items: center;
  color: #717171;
  font-weight: 600;
}

.order-total-dt div {
  font-size: 16px;
  margin-bottom: 20px;
}

.order-number {
  margin-left: auto;
}

.order-text {
  color: #000;
}

.ttl-clr {
  color: #000;
}

.divider-line {
  display: block;
  width: 100%;
  height: 1px;
  background: #efefef;
  margin-bottom: 20px;
}

.coupon-code-block .form-control {
  background-color: #f9f9f9 !important;
}

.apply-btn {
  position: absolute;
  right: 5px;
  height: 40px;
  bottom: 5px;
  padding: 0 20px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  background: #1d3f73;
  display: inline-block;
  border-radius: 3px;
  border: 0;
}

.confirmation-btn {
  text-align: center;
}

.confirmation-btn span {
  font-size: 14px;
  color: #000;
  margin-top: 13px;
  display: block;
}

.select-tickets-block {
  padding: 0 20px 40px;
}

.select-ticket-action {
  display: flex;
  width: 100%;
  align-items: center;
}

.select-tickets-block h6 {
  font-size: 18px;
  font-family: inherit;
  color: #000;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 27px;
}

.ticket-price {
  font-size: 18px;
  font-weight: 500;
  color: #717171;
}

.quantity {
  margin-left: auto;
}

.counter {
  width: 150px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter input {
  width: 40px;
  border: 0;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: transparent;
  color: #000;
  appearance: none;
  outline: 0;
}

.counter span {
  display: block;
  font-size: 24px;
  padding: 0;
  line-height: 26px;
  cursor: pointer;
  color: #000;
  user-select: none;
  background: #fff;
  border: 2px solid #bbb;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
}

.counter span:hover {
  color: #fff;
  background: #1d3f73;
  border: 2px solid #1d3f73;
}

.select-tickets-block p {
  margin-bottom: 20px;
  margin-top: 20px;
}

.xtotel-tickets-count {
  border-top: 1px solid #efefef;
  padding-top: 20px;
}

.x-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 13px;
}

.xtotel-tickets-count h4 {
  font-size: 22px;
  margin-bottom: 0;
  color: #000;
}
.seats {
  /* display: flex; */
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.buyseat {
  display: flex;
  /* flex: 0 0 14.28571%; */
  padding: 5px;
  gap: 10px;
  /* width:50px; */
  /* height: max-content; */
  position: relative;
}
/* .seat:nth-child(3) {
  margin-right: 14.28571%;
} */
.buyseat input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
.buyseat input[type="checkbox"]:checked + label {
  background: #26a69a !important;
  color: #ffffff !important;
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.buyseat input[type="checkbox"]:disabled + label {
  background: #ddd !important;
  color: white !important;
  overflow: hidden !important;
  border: 3px solid #dddddd !important;
}
.buyseat input[type="checkbox"]:disabled + label:after {
  /* content: ""; */
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.buyseat input[type="checkbox"]:disabled + label:after {
  /* content: ""; */
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.buyseat input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.buyseat label {
  display: block;
  position: relative;
  width: 100% !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1rem;
  padding: 2px;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
  border: 3px solid #26a69a !important;
}
.buyseat label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}

/*  */

.buyseatSeason {
  display: flex;
  /* flex: 0 0 14.28571%; */
  padding: 5px;
  gap: 10px;
  /* width:50px; */
  /* height: max-content; */
  position: relative;
}
/* .seat:nth-child(3) {
  margin-right: 14.28571%;
} */
.buyseatSeason input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
.buyseatSeason input[type="checkbox"]:disabled + label {
  background: #ddd !important;
  color: white !important;
  overflow: hidden !important;
  border: 3px solid #db6977 !important;
}

.buyseatSeason input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.buyseatSeason label {
  display: block;
  position: relative;
  width: 100% !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1rem;
  padding: 2px;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
  border: 3px solid #db6977 !important;
}
.buyseatSeason label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}

/*  */

.buyseatBundle {
  display: flex;
  /* flex: 0 0 14.28571%; */
  padding: 5px;
  gap: 10px;
  /* width:50px; */
  /* height: max-content; */
  position: relative;
}
/* .seat:nth-child(3) {
  margin-right: 14.28571%;
} */
.buyseatBundle input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.buyseatBundle input[type="checkbox"]:disabled + label {
  background: #ddd !important;
  color: white !important;
  overflow: hidden !important;
  border: 3px solid #bada55 !important;
}

.buyseatBundle input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}
.buyseatBundle label {
  display: block;
  position: relative;
  width: 100% !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1rem;
  padding: 2px;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
  border: 3px solid #bada55 !important;
}
.buyseatBundle label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #ccc;
}
.tablesec1 {
  background-color: #5e6fde;
  color: white;
}
.tablesec2 {
  background-color: #e2102a8f;
  color: white;
}
.OwnerTable {
  border: 1px solid #e5e5e5;
  margin: 10px;
  background: white;
  border-radius: 3px;
  align-items: center;
}
.OwnerTableHead {
  background-color: #e5e5e5;
  color: black;
  border-color: #e5e5e5;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
}
.OwnerTableBody {
  border-color: inherit;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  width: max-content;
}
/* Payment Status Css */

.booking-confirmed-img {
  margin-bottom: 27px;
}

.booking-confirmed-img img {
  width: 60px;
}

.booking-confirmed-top h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.add-calender-booking {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.add-calender-booking h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.cb-icon {
  color: #717171;
  font-size: 14px;
  width: 30px;
  height: 30px;
  background: #e8f7f7;
  margin-left: 10px;
  line-height: 30px;
  border-radius: 50%;
}

.cb-icon:hover {
  color: #fff !important;
  background: #1d3f73;
}

.booking-confirmed-bottom {
  padding: 0 5px 5px;
}

.booking-confirmed-bottom-bg {
  background: #f9f9f9;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.buyer-name {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  font-family: inherit;
}

.booking-total-tickets span {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-top: 13px;
}

.booking-total-grand {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  margin-top: 13px;
}

.booking-total-grand span {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-left: 2px;
}
.loader {
  position: relative;
}

.circleLoading {
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  border-radius: 100%;
  opacity: 0;
  animation: pulse_4923 4s infinite linear;
  border: 0.5px solid #000000;
  box-shadow: 0px 0px 5px #eef0e5;
}
.bt-leftright {
  /* flex-direction: column; */
}
.circleLoading:nth-child(1) {
  animation-delay: 0.2s;
}

.circleLoading:nth-child(2) {
  animation-delay: 0.4s;
}

.circleLoading:nth-child(3) {
  animation-delay: 0.8s;
}

.circleLoading:nth-child(4) {
  animation-delay: 1s;
}
.seat-scroll {
  overflow-x: scroll;
}
@keyframes pulse_4923 {
  0% {
    opacity: 0;
    width: 0px;
    height: 0px;
    transform: translate(-50%, -50%) scale(1);
  }

  10% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(2);
  }

  100% {
    opacity: 0;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media (min-width: 320px) and (max-width: 600px) {
.new-event-thumbnail-img img {
  width: 245px;
}
}
.plusminusBtn{
  background-color: transparent;
  border: none;
  scale: 2;
}