.wrapper {
  margin-top: 60px;
}
.wrapper-home {
  margin-top: 75px;
}
/* .hero-banner {
  background-image: url("../../assets/img/banner.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0 80px;
} */
 .home-img-style{
  background-image: url(../../assets/img/Tiq8-Banner2025try.png);
  background-position: center center;
  background-repeat: repeat;
  /* background-size: cover; */
  object-fit: cover;
  padding: 20px ;
/* position: relative; */
/* height: 455px; */

 }
 .home-new-logo-style{
  scale: 1.8 !important;
  margin-top: 4%;
 }
.hero-banner-home {
  background:linear-gradient(
    rgba(255, 219, 41, 0.8), 
    rgba(246, 206, 34, 0.8)
  );
  object-fit: cover;
  background-repeat: no-repeat; 
  background-size: cover;
  padding: 0px ; 
  background-position: center;
 
}
.new-box-banner-text{
  margin-top: 2%;
  margin-bottom: 4%;
}
.hero-banner-content {
  text-align: start;
}

.hero-banner-content h2 {
  font-size: 32px;
  line-height: 38.73px;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 0 30px;
  /* letter-spacing: 3px; */
}

.hero-banner-content p {
  font-size: 18px;
  color: #000000;
  margin-bottom: 33px;
  padding: 0 30px;
  line-height: 32px;
  width: 35%;
}

.main-btn {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #1d3f73;
  height: 50px;
  display: inline-block;
  line-height: 49px;
  padding: 0 30px;
  border-radius: 3px;
  border: 1px solid #1d3f73;
}

.btn-hover:hover {
  color: #fff !important;
  background: #265399;
  border: 1px solid #265399;
}

.co-main-btn {
  color: #000;
  background: #fff !important;
  border-radius: 3px;
  font-size: 16px;
  border: 1px solid #bbb;
  height: 40px;
}

.co-main-btn:hover {
  font-size: 16px;
  background: #e8f7f7 !important;
  border-radius: 3px;
  border: 1px solid #bbb;
  height: 40px;
}

.co-btn-width {
  width: 270px;
}

.p-80 {
  padding: 57px 0 64px;
}

.explore-events {
}

.flex-d {
  display: flex;
  align-items: center;
  width: 100%;
}

.title-view-link {
  margin-left: auto;
  color: #717171;
  font-size: 14px;
  font-weight: 500;
}

.title-view-link i {
  margin-left: 9px;
}

.title-view-link:hover {
  color: #1d3f73 !important;
}

.main-title h3 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
  line-height: 52px;
}

.filter-tag {
  text-align: center;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  /* padding: 31px 0 0; */
  margin-bottom: 35px;
}

.filter-tag a.active,
.filter-tag a:hover {
  background: rgba(29, 63, 115, 1);
  color: white !important;
}
.filter-tag button.active,
.filter-tag button:hover {
  background: #efefef;
  color: #000 !important;
}
.filter-tag button {
  font-size: 12px;
  color: #717171 !important;
  border: 1px solid #efefef;
  background: #fff;
  height: 32px;
  padding: 7px 20px;
  border-radius: 30px;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
}
.filter-tag a {
  font-size: 14px;
  color: black !important;
  background: #fff;
  height: 32px;
  padding: 7px 20px;
  border-radius: 30px;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
  border: 1px solid black;
}
.mixitup-control-active {
  background: #fff;
  color: #1d3f73 !important;
}

.controls {
  margin-bottom: 15px;
}

.control {
  position: relative;
  display: inline-block;
  background: #fff;
  height: 40px;
  padding: 0 20px;
  margin-bottom: 5px;
  margin-right: 2px;
  cursor: pointer;
  border: transparent;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: background 150ms;
}

.control:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.control[data-filter]:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  background: transparent;
  transition: background-color 150ms, border-color 150ms;
}

.mixitup-control-active[data-filter]:after {
  background: transparent;
  border-bottom: 3px solid #1d3f73;
  color: #1d3f73;
}

.main-card {
  position: relative;
  border: 1px solid #efefef;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
}

.event-thumbnail {
  width: 100%;
  position: relative;
  padding: 0;
}

.thumbnail-img {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.event-thumbnail > .thumbnail-img::after {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.thumbnail-img:hover {
  outline: 0 !important;
  color: #000;
  transition: 0.3s;
  text-decoration: none;
}

.event-thumbnail a img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: transform 0.5s ease;
}

.event-thumbnail a:hover img {
  transform: scale(1.05);
}

.event-content {
  padding: 15px 20px 20px;
}

.event-content > .event-title {
  font-size: 16px;
  font-weight: 600;
  color: #000 !important;
  display: block;
  line-height: 25px;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.event-content > .event-title:hover {
  color: #000 !important;
}

.duration-price-remaining {
  display: flex;
  width: 100%;
  align-items: center;
}

.remaining {
  font-size: 14px;
  font-weight: 400;
  margin-left: auto;
  color: #717171;
}

.duration-price {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.remaining i {
  font-size: 12px;
  margin-right: 7px;
  transform: rotate(300deg);
}

.event-footer {
  background: rgb(106, 192, 69, 3%);
  padding: 10px 20px;
}

.event-timing {
  display: flex;
  align-items: center;
  color: #717171;
  font-size: 13px;
}

.publish-time {
  margin-left: auto;
}

.publish-date {
  display: flex;
  align-items: center;
}

.dot {
  font-size: 4px;
  margin: 0 10px;
}

.bookmark-icon {
  color: #fff;
  font-size: 20px;
  position: absolute;
  z-index: 101;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background-color: #1d3f73;
  display: block;
  height: 45px;
  width: 45px;
  line-height: 46px;
  border-radius: 30px;
  transition: all 0.4s;
}

.bookmark-icon:before,
.bookmark-icon:after {
  transition: opacity 0.3s, transform 0.4s cubic-bezier(0, -0.41, 0.19, 2.5);
  font-family: "Font Awesome 6 Free";
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  top: 0px;
}

.bookmark-icon:before {
  content: "\f02e";
  transform: scale(0.95);
}

.bookmark-icon:after {
  content: "\f02e";
  opacity: 0;
  transform: scale(0.3);
  font-weight: 900;
}

.bookmark-icon.bookmarked:after {
  opacity: 1;
  transform: scale(0.95);
}

.bookmark-icon:hover,
.like-icon.bookmarked {
  background-color: #1d3f73;
  color: #fff;
}

.bookmark-icon.bookmarked:before {
  opacity: 0;
}

.browse-btn {
  text-align: center;
  margin-top: 40px;
}

.host-engaging-event-block {
  background: #fff;
}

.main-title p {
  font-size: 16px;
  line-height: 30px;
  margin: 10px 0 33px;
  color: #717171;
}

.host-item {
  padding: 30px;
}

.host-img {
  display: block;
  margin-bottom: 20px;
}

.host-img img {
  width: 50px !important;
}

.host-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
  color: #000;
}

.host-item p {
  margin-bottom: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #1d3f73 !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: #efefef !important;
}

.owl-nav .owl-prev {
  left: -20px;
  font-size: 20px !important;
  position: absolute;
  top: 45%;
  padding: 0 !important;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  color: #717171 !important;
  background: #fff !important;
  border-radius: 3px !important;
  border: 1px solid #efefef !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.owl-nav .owl-next {
  font-size: 20px !important;
  position: absolute;
  right: -20px;
  top: 45%;
  padding: 0 !important;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  color: #717171 !important;
  background: #fff !important;
  border-radius: 3px !important;
  border: 1px solid #efefef !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #1d3f73 !important;
  border: 1px solid #1d3f73 !important;
}

.owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #1d3f73 !important;
  background: #1d3f73 !important;
}

.engaging-slider .owl-nav .owl-prev {
  top: 35%;
}

.engaging-slider .owl-nav .owl-next {
  top: 35%;
}

.feature-group-list {
  border-top: 2px solid #efefef;
  padding-top: 3px;
}

.mt-46 {
  margin-top: 57px !important;
}

.feature-item {
  margin-top: 37px;
}

.feature-icon {
  display: block;
  margin-bottom: 24px;
}

.feature-icon img {
  width: 50px;
}

.feature-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
  color: #000;
}

.feature-item p {
  margin-bottom: 0;
}

.host-step-block {
  background-color: #fff;
}

.step-link {
  font-size: 22px;
  font-weight: 100;
  position: relative;
  box-sizing: border-box;
  width: 25%;
  display: block;
  border: none;
  border-bottom: 1px solid #efefef;
  padding: 1rem;
  color: #bbb;
  background: transparent;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.step-tabs .step-link.active,
.step-tabs .show > .step-link {
  color: #fff;
  background-color: #1d3f73;
}

.step-tabs .step-link:hover {
  background-color: #e8f7f7;
}

.step-tabs .step-link.active span,
.step-tabs .show > .step-link span {
  color: #fff !important;
}

.step-link span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.step-tabs .step-link.active:hover {
  color: #fff;
  background-color: #1d3f73;
}

.step-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding: 37px 0 0;
  margin-bottom: 10px;
}

.step-item {
  margin-top: 37px;
}

.step-icon {
  display: block;
  margin-bottom: 24px;
}

.step-icon img {
  width: 50px;
}

.step-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
  color: #000;
}

.step-item p {
  margin-bottom: 0;
}

.testimonial-slider-area {
  border-top: 2px solid #efefef;
  padding-top: 38px;
}

.testimonial-content {
  padding: 30px;
  position: relative;
}

.testimonial-text p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 30px;
}

.testimonial-user-dt {
  margin-top: 33px;
}

.testimonial-user-dt h5 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.testimonial-user-dt span {
  font-size: 14px;
  color: #717171;
  margin: 10px 0;
  display: block;
}

.testimonial-user-dt ul li {
  display: inline-block;
}

.testimonial-user-dt ul li i {
  color: #ffad21;
}

.quote-icon {
  position: absolute;
  right: 30px;
  font-size: 60px;
  bottom: 3%;
  color: #efefef;
}

.our-organisations-block {
  background-color: #1d3f73;
}

.our-organisations-block .main-title h3 {
  color: #fff;
}

.organisations-area {
  margin-top: 64px;
}

.sponsor {
  text-align: center;
  line-height: 7;
}

.sponsor a img:hover {
  opacity: 1;
}

.sponsor a img {
  display: inline-block !important;
  width: auto !important;
  max-width: 100%;
  height: auto;
  opacity: 0.8;
  vertical-align: bottom;
}
@media (max-width: 575.98px) {
  .step-link {
    width: 100%;
  }
}
.attendees-card {
  height: 250px;
}

.night-mode .hometextnight {
  color: #000 !important;
}
.night-mode .seasontextnight {
  color: #6f767e !important;
}
.night-mode .form-group {
  background-color: transparent !important;
}
.night-mode .registration-title {
  color: white !important;
}
.night-mode .registration .form-control {
  background-color: #272b30 !important;
  color: #717171 !important;
}
.night-mode .showpassnight {
  color: white !important;
}
.night-mode .signoutbtn-haeder {
  color: #6f767e;
}
.night-mode .signoutbtn-haeder:hover {
  color: white;
}
.night-mode .selectpicker-add-orgReport {
  color: white;
}
.night-mode .selectpicker-add-orgReport option {
  color: #000;
}
.night-mode .landinginputnight {
  color: #000 !important;
  background-color: white !important;
  border: 1px solid #efefef !important;
}
.night-mode .form-group .inputstylenightmode {
  background-color: #272b30 !important;
  color: #717171 !important;
  border: none !important;
}
.night-mode .stsearch-box input {
  background-color: #272b30 !important;
  color: #717171 !important;
  border: none !important;
}
.night-mode .stdropdown-container {
  background-color: transparent !important;
}
.home-main-tab {
  min-width: 134px;
  height: 36px;
  gap: 10px;
  border-radius: 50px;
  border: 0.5px 0px 0px 0px;
  /* opacity: 0px; */
  background: rgba(255, 255, 255, 1);
  border: 0.5px solid var(--Stroke---Medium, rgba(89, 89, 89, 1));
}
.home-main-tab:focus {
  background-color: rgba(29, 63, 115, 1);
  color: white;
}
.home-main-tab.selected {
  background-color: rgba(29, 63, 115, 1);
  color: white;
}
.home-calendar {
  height: 280px;
}
.home-calendar .fc .fc-scrollgrid-liquid {
  height: 28% !important;
}
.home-calendar .fc-scrollgrid-sync-inner {
  background-color: white !important;
}
.home-calendar .fc-daygrid-dot-event {
  padding: 0;
}
.new-home-calendar .fc td,
.fc th {
  padding: 4px;
}
.home-calendar .fc .fc-daygrid-day-events {
  margin-top: 0px;
  position: relative;
}
.home-calendar .fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-right: 0px;
}
.home-calendar .fc-direction-ltr .fc-daygrid-event.fc-event-start {
  margin-left: 0px;
}
.home-calendar .thumbnail-img {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.new-calendar-event {
  width: 100%;
}
.new-home-calendar .fc-theme-standard .fc-scrollgrid {
  border: none;
}
.home-calendar .fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 6px 4px;
}
.home-calendar .fc-theme-standard td,
.fc-theme-standard th {
  border: none;
}
.home-cal-text {
  cursor: pointer;
  z-index: 999;
}
.calendar-img {
  height: 103px !important;
}
.home-calendar .fc-h-event {
  background-color: white !important;
  border: none !important;
}
/* Remove extra margins or padding between dates */
.fc .fc-daygrid-day {
  padding: 0;
  margin: 0;
}
/* .fc-daygrid-dot-event.fc-event-mirror,  */
.home-calendar .fc-daygrid-dot-event:hover {
  background: none !important;
}
.home-calendar .fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 9999;
  background: white;
  color: #000 !important;
  margin: 3px;
}
.addresstext-calendar {
  word-break: break-all !important;
}
.home-calendar .fc-daygrid-day-number {
  color: #000 !important;
}
.home-month-calendar {
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 9999;
  background: white;
  color: #000 !important;
  margin: 3px;
}
.month-calendar-style .fc .fc-scrollgrid-liquid {
  height: 100%;
  background: white;
}
.month-calendar-style .fc td {
  height: 19%;
}
.month-calendar-style .fc-theme-standard td,
.fc-theme-standard th {
  border: 5px solid #f5f7f9;
  padding: 0px;
}
.month-calendar-style .fc-theme-standard .fc-scrollgrid {
  border: none;
}
.month-calendar-style .fc .fc-daygrid-day-top {
  display: inline-block;
  /* flex-direction: row;
  position: absolute; */
  z-index: 9999;
  background: rgba(183, 183, 183, 1) !important;
  color: white !important;
  margin: 5px;
  padding: 3px;
}
.month-calendar-style .fc-daygrid-day-number {
  color: white !important;
}
.month-calendar-style .fc .fc-daygrid-day-events {
  margin-top: 0px;
  position: relative;
}
.calendar-event {
  background-color: #d9d9d9;
  width: 100%;
  padding: 4px;
}
.calendar-event p {
  margin-bottom: -1rem;
}

/* :root {
  --fc-border-color: black;
  --fc-daygrid-event-dot-width: 5px;
  
} */

/* Ensure the table layout collapses spacing */
/* .new-home-calendar .fc .fc-scrollgrid-sync-table {
  border-spacing: 2px;
  border-collapse: collapse;
} */

/* Adjust the event alignment to fit snugly */
/* .fc-daygrid-event {
  margin: 1px 0; 
  padding:6px;  
} */

.home-search .inputGroup {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  background-color: white;
  overflow: hidden;
}
.home-search .inputWrapper {
  display: flex;
  align-items: center;
  border-right: 1px solid #000;
  padding: 10px;
}
.home-search .icon {
  margin-right: 8px;
  font-size: 16px;
}
.home-search input {
  border: none;
  outline: none;
  font-size: 14px;
  width: 185px;
}
.home-search button {
  background-color: #004085;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
}
.home-search {
  margin-top: -3%;
}
.home-search-btn {
  padding: 19px;
}
.inputWrapper button {
  background-color: transparent;
}
.search-input-home {
  width: 400px !important;
}
/* @media only screen and (min-width: 575.98px) and (max-width: 600px) {
  .fc .fc-toolbar.fc-header-toolbar{
    width: 370% !important;
  }
} */
 .res-home-banner-text{
  width: 100% !important;
 }
@media (max-width: 600px) {
  .home-calendar .fc .fc-scrollgrid-liquid {
    height: 70% !important;
  }
  .home-calendar .fc .fc-toolbar.fc-header-toolbar {
    width: 295% !important;
  }
  .month-calendar-style .fc .fc-toolbar.fc-header-toolbar {
    width: 265% !important;
  }
  .hero-banner-home {
    padding: 0px;
  }
  .hero-banner-content p {
    font-size: 15px;
  }
  .hero-banner-content h2 {
    margin-top: 15px;
    font-size: 26px;
    margin-bottom: 0px;
  }
  .search-input-home {
    width: 185px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 575.98px) {
  .home-calendar .fc .fc-scrollgrid-liquid {
    height: 70% !important;
  }
  .home-calendar .fc .fc-toolbar.fc-header-toolbar {
    width: 265% !important;
  }
  .month-calendar-style .fc .fc-toolbar.fc-header-toolbar {
    width: 265% !important;
  }
  .hero-banner-home {
    padding: 0px !important;
  }
  .home-img-style{

    height: 230px;
  }
  .hero-banner-content p {
    font-size: 13px !important;
    padding: 0px !important;
    margin-bottom: 40px !important;
    line-height: 19px !important;
  }
  .hero-banner-content h2 {
    margin-top: 15px !important;
    font-size: 26px !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
  }
  .res-home-banner-text {
    word-wrap: break-word;
    width: 85% !important;
  }
  .home-search .inputGroup {
    display: inline;
    border: none;
    background-color: white;
  }
  .home-search .inputWrapper {
    border: 1px solid rgb(180, 180, 180);
    background-color: white;
  }
  .search-input-home {
    width: 188px !important;
  }
  .home-res-search {
    background-color: white;
    border: 1px solid rgb(180, 180, 180);
  }
}
@media (max-width: 768px) {
  .home-calendar .fc .fc-scrollgrid-liquid {
    height: 100% !important;
    overflow: scroll;
    width: auto;
  }
  .home-calendar .fc .fc-toolbar.fc-header-toolbar {
    width: 190%;
  }
  .home-calendar .fc .fc-scrollgrid-section-body table {
    width: 1000px !important;
  }
  .home-calendar .fc .fc-scrollgrid-section table {
    width: 1000px !important;
  }
  .home-calendar {
    /* overflow-y: scroll; */
    overflow-x: scroll !important;
  }
  .home-calendar .fc .fc-scrollgrid {
    table-layout: fixed;
    /* width: 193%; */
  }
  .month-calendar-style {
    /* overflow-y: scroll; */
    overflow-x: scroll !important;
  }
  .month-calendar-style .fc .fc-scrollgrid-section-body table {
    width: 1000px !important;
  }
  .month-calendar-style .fc .fc-scrollgrid-section table {
    width: 1000px !important;
  }
  .month-calendar-style .fc .fc-scrollgrid-liquid {
    height: 100% !important;
    overflow: scroll;
    width: auto;
  }
  .month-calendar-style .fc .fc-view-harness {
    height: 750.185px !important;
  }
  .month-calendar-style .fc .fc-toolbar.fc-header-toolbar {
    width: 190%;
  }
  .hero-banner-home {
    padding: 0px;
  }
  /* .fc .fc-scroller-harness {
  overflow: auto !important;
} */
}
@media (min-width: 767px) and (max-width: 991.98px) {
  .hero-banner-home {
    padding: 0px;
  }
  .hero-banner-content p {
    width: 70%;
  }
  .home-search input {
    width: 140px;
  }
  .search-input-home {
    width: 161px !important;
  }
  .home-img-style {
    height: 380px;
  }
}
.home-scroll {
  width: 330px !important;
}
.scroll_comp {
  border-radius: 0.7rem;
  padding-bottom: 0.5rem;
  display: flex;
  overflow: auto;
  gap: 10px;
  z-index: 1 !important;
}
.scroll_comp::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.scroll_comp::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 18px;
  border: 3px solid white;
  height: 200px !important;
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .hero-banner-content h2 {
    padding: 0px 65px;
  }
  .hero-banner-content p {
    padding: 0px 65px !important;
    width: 40%;
  }
}
.watch-video iframe{
  display: block !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background: linear-gradient(rgba(255, 219, 41, 0.8), rgba(246, 206, 34, 0.8));
}
.home-explore-margin{
  margin-top: 7%;
}