/*--- Contact Us Page ---*/

.contact-banner-block {
    padding: 5px;
}

.contact-hero-banner {
    min-height: 461px;
    width: 100%;
    padding: 50px;
    background: url(../../assets/img/sign_bg.jpg);
	border-radius: 3px;
}

.contact-hero-banner-info h3 {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 15px;
}

.contact-hero-banner-info p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 60px;
}

.contact-icon  {
    color: #fff;
    font-size: 20px;
    margin-right: 17px;
}

.contact-info a {
    font-size: 18px;
    color: #fff !important;
	font-weight: 500;
}
.contact-link{
    font-size: 18px;
    color: #fff !important;
	font-weight: 500;
}
.contact-link:hover{
    color: #fff !important;

}
.contact-info a:hover {
    color: #fff !important;
}

.contact-hero-banner-info ul li {
    margin-bottom: 20px;
}

.contact-hero-banner-info ul li:last-child {
    margin-bottom: 0;
}
