input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}
.add-btn-venue {
  background-color: #1d3f73;
  color: white;
  border-radius: 5px;
  height: 35px;
  padding: 0 10px;
  border: none;
  width: 80px;
  font-size: 16px;
}
.gatezonebox {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: scroll;
}
.seatingArrengmentBox {
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-top: 30px;
}
.venue-input {
  width: 170px !important;
}
.removeBtnVenue {
  background-color: #e76161;
  color: white;
  border: 1px solid #e76161;
  padding: 8px 10px;
  border-radius: 4px;
  margin-top: 20px;
  margin-inline-start: 10px;
}
.removeBtnVenue:hover {
  color: #e76161;
  border: 1px solid #e76161;
  background-color: white;
  cursor: pointer;
}
.saveBtn {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #1d3f73;
  height: 37px;
  display: inline-block;
  line-height: 36px;
  padding: 0 30px;
  border-radius: 3px;
  border: 1px solid #1d3f73;
}
.cancelBtn {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #e76161;
  height: 37px;
  display: inline-block;
  line-height: 36px;
  padding: 0 30px;
  border-radius: 3px;
  border: 1px solid #e76161;
}
.yesBtn {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #1d3f73;
  height: 37px;
  display: inline-block;
  line-height: 36px;
  padding: 0 30px;
  border-radius: 3px;
  border: 1px solid #1d3f73;
}
.closeIcon-venue {
  height: 30px;
  width: 30px;
}
