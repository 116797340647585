.payNowButton {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 9px 13px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  .payNowButton:hover {
    filter: contrast(115%);
  }
  
  .payNowButton:disabled {
    opacity: 0.5;
    cursor: default;
  }
  .payNowButtonCancel {
    background-color: #e76161;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 9px 13px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  .payNowButtonCancel:hover {
    color: #e76161;
    border: 1px solid #e76161;
    background-color: white;
    cursor: pointer;
  }
  .go2150698616:before {
    position: relative;
    float: left;
    left: 50%;
    width: 2.3em !important;
    line-height: 2.4em !important;
    border-radius: 50%;
    bottom: -3.99rem;
}
.go3842760039:before {
    content: "\2b24";
    color: white;
    background-color: #1d3f73;
    margin:0 10px !important;
}
.go433304200:before {
    content: "✓";
    color: white;
    background-color: #1d3f73;
}
.go2335061104:before {
    content: "\2b24";
    color: silver;
    background-color: white;
}
.confirm-owner-btn{
    background-color: #1d3f73;
    color: white;
    padding: 0 10px;
    height: 35px;
    line-height: 32px;
    border: none;
    font-size: 14px;
    border-radius: 5px;
}

/* resell Payment status */

.booking-confirmed-img {
    margin-bottom: 27px;
}

.booking-confirmed-img img {
    width: 60px;
}

.booking-confirmed-top h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
}

.add-calender-booking {
    display: flex;
    justify-content: center;
    align-items: center;
	margin-top: 18px;
}

.add-calender-booking h5 {
    font-size: 14px;
    font-weight: 600;
	margin-bottom: 0;
}

.cb-icon {
    color: #717171;
    font-size: 14px;
    width: 30px;
    height: 30px;
    background: #e8f7f7;
    margin-left: 10px;
    line-height: 30px;
    border-radius: 50%;
}

.cb-icon:hover {
    color: #fff !important;
    background: #1d3f73;
}

.booking-confirmed-bottom {
    padding: 0 5px 5px;
}

.booking-confirmed-bottom-bg {
    background: #f9f9f9;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.buyer-name {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    font-family: inherit;
}

.booking-total-tickets span {
	margin-bottom: 0;
	font-size: 14px;
	color: #000;
	display: inline-block;
	margin-top: 13px;
}

.booking-total-grand {
	margin-bottom: 0;
	font-size: 14px;
	color: #000;
	margin-top: 13px;
}

.booking-total-grand span{
	margin-bottom: 0;
	font-size: 14px;
	color: #000;
	display: inline-block;
	margin-left: 2px;
}

