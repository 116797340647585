.removeBtnleague {
  height: 35px;
  margin-top: 7px;
  background-color: #e76161;
  color: white;
  border: 1px solid #e76161;
  padding: 8px 10px;
  border-radius: 4px;
  margin-inline-start: 10px;
}
.leagueinputSty {
  display: flex;
  gap: 17rem;
}
.Leaguepopup {
  height: 500px !important;
  padding-right: 0% !important;
  margin-top: 5% !important;
}
.leaguebody {
  border: none !important;
}
.createteamscroll {
  height: 300px;
  overflow-y: scroll;
}
.ADDBtn {
  background-color: #1d3f73;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  border: none;
  font-size: large;
  padding: 0px;
}
.league-input {
  width: 100%;
  max-width: 400px;
  min-width: 200px;
}
.night-mode .inputleaguenight {
  background-color: #272b30 !important;
  color: #717171 !important;
}

/* LeaguePage css */

.league-page-search {
  display: flex;
  width: 250px;
  height: 35px;
  background-color: #717171;
  position: absolute;
  margin-top: -10px;
  right: 326px !important;
}
.league-page-search-input {
  width: 100%;
  height: 100%;
  padding: 2px 40px 2px 10px;
}
.league-page-search-icon {
  position: absolute;
  right: 0px;
  padding: 1px 6px;
  font-size: 22px;
  color: white;
  background-color: #1d3f73;
}
/* Breadcrumbs.css */
.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  color: black;
}

.breadcrumbs-separator {
  margin: 0 5px;
}
.league-page-search-breadcrumb {
  display: flex;
  position: absolute;
  top: 30px;
  /* left: 100px; */
}
.league-page-search-logo {
  position: absolute;
  left: 290px;
  top: 250px;
  width: 400px;
  height: 100px;
  border-radius: 10px;
}

.seasonal-card {
  width: 47%;
}
.landing-header-logo img {
  width: 100px;
  height: 100px;
  margin-top: -30px;
  border-radius: 9999px;
  outline-color: rgb(9, 153, 161);
  outline-style: solid;
}

.seasonal-card .resell-img-style {
  width: 225px !important;
  height: 147px;
}
.hero-banner-landing {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 0 30px;
}
.landing-main-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.control:hover {
  background: #ffffff;
  border-bottom: #1d3f73;
}
.league-page-team-container {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 18px;
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.league-page-team-card {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 0.5px solid #e3e3e3;
  padding: 8px 18px;
  border-radius: 3px;
}
.league-page-team-card-img {
  height: 32.43px;
  width: 32.43px;
}
.league-page-team-card-name {
  margin: auto 4px;
  color: black !important;
}
.league-page-about {
  display: flex;
  justify-content: space-between;
  background-color: #5f5f5f;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  height: 100px;
  color: white;
  font-size: 16px;
  padding: 0 10px;
}
.about-social-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
}
.about-social-card span {
  color: white;
}

.about-social-card-icon {
  font-size: 26px;
  color: white;
}
