/* --- Profile Page --- */

.hero-cover-block {
  height: 260px;
  position: relative;
}

.hero-cover {
  position: absolute;
  inset: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden;
  background-color: rgba(80, 101, 246, 0.2);
}

.hero-cover-img {
  background-size: cover;
  background-position: center center;
  position: absolute;
  inset: 0px;
  filter: none;
  transition: all 0.12s ease-in-out 0s;
  opacity: 1;
  width: 100%;
  height: -webkit-fill-available;
}

.user-dt-block {
  padding-bottom: 64px;
}

.user-left-dt {
  text-align: center;
  padding: 30px;
  margin-top: -70px;
}

.upload-cover {
  display: inline-block;
  color: #e44d3a;
  font-size: 16px;
  position: absolute;
  top: 30px;
  right: 0;
  font-weight: 600;
  margin-right: 0;
  width: 100%;
}

.upload-cover .cover-img-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  float: left;
}

.upload-cover .cover-img-btn [type="file"] + label {
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 12px 20px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0;
  float: right;
}

.upload-cover .cover-img-btn [type="file"] + label:hover {
  background: #265399;
  color: #fff;
}

.user-avatar-img {
  position: relative;
}

.user-avatar-img img {
  width: 130px;
  height: 130px;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #1d3f73;
  padding: 2px;
  object-fit: cover;
}

.avatar-img-btn {
  display: inline-block;
  color: #e44d3a;
  font-size: 16px;
  position: absolute;
  bottom: 4px;
  left: 64%;
  transform: translateX(-50%);
  margin-right: 0;
}

.avatar-img-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  float: left;
}

.avatar-img-btn [type="file"] + label {
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0;
  float: right;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.avatar-img-btn [type="file"] + label:hover {
  background: #265399;
  color: #fff;
}

.user-dts .user-name {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-top: 23px;
}

.ff-block {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 30px;
}

.ff-block a {
  font-size: 14px;
  color: #000 !important;
  display: inline-block;
  width: 50%;
}
.ff-block-profile {
  font-size: 14px;
  color: #000 !important;
  display: inline-block;
  width: 50%;
}
.night-mode .ff-block-profile {
  color: white !important;
}
.ff-block a:hover {
  color: #1d3f73 !important;
}

.ff-block a span {
  display: block;
  font-size: 18px;
  font-weight: 500;
}

.ff-block a:first-child {
  border-right: 1px solid #efefef;
}

.verify-badge {
  margin-left: 8px;
  font-size: 15px;
  color: #1d3f73 !important;
}

.user-description {
  margin-top: 30px;
}

.user-btns {
  margin-top: 30px;
}

.user-email {
  font-size: 16px !important;
  margin-top: 15px;
  display: block;
}

.profile-social-link {
  margin-top: 37px;
}

.profile-social-link h6 {
  font-size: 16px;
  font-weight: 600;
  text-transform: inherit;
  color: #000;
  margin-bottom: 20px;
}

.social-links > .social-link {
  font-size: 18px;
  width: 40px;
  height: 40px;
  border: 2px solid #1d3f73;
  border-radius: 100%;
  text-align: center;
  color: #1d3f73 !important;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 10px;
  margin-right: 7px;
  padding: 10px 9px;
}

.social-links > .social-link:hover {
  color: #fff !important;
  border: 2px solid #1d3f73;
  background-color: #1d3f73;
}

.user-right-dt {
  text-align: center;
  padding: 0;
  margin-top: -70px;
}

.garren-line-tab {
  border: 1px solid #efefef;
  border-radius: 3px;
  background-color: #fff;
}

.border-bottom {
  border-bottom: 1px solid #efefef !important;
}

.garren-line-tab .nav-link {
  font-size: 14px;
  font-weight: 400;
  color: #717171;
  position: relative;
  padding: 10px !important;
  border-radius: 3px;
}

.garren-line-tab .nav-link.active,
.garren-line-tab .nav-link.active:hover {
  color: #fff !important;
  background-color: #1d3f73 !important;
}

.garren-line-tab .nav-link:hover {
  color: #1d3f73 !important;
}

.garren-line-tab .nav-link i {
  font-size: 14px;
  margin-right: 8px;
}

.right-profile {
  margin-top: 25px;
}

.tab-link {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;
  width: 33.33%;
  display: block;
  border: none;
  border-bottom: 3px solid transparent;
  padding: 1rem;
  color: #717171;
  background: transparent;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.tab-link-setting{
  width: 100% !important;
}
.custom-tabs .tab-link.active,
.tab-tabs .show > .tab-link {
  color: #1d3f73;
  background-color: #fff;
  border-bottom: 3px solid #1d3f73;
}

.custom-tabs .tab-link:hover {
  color: #1d3f73;
  background-color: #fff;
  border-bottom: 3px solid transparent;
}

.custom-tabs .tab-link.active span,
.tab-tabs .show > .tab-link span {
  color: #fff;
}

.custom-tabs .tab-link.active:hover {
  color: #1d3f73;
  background-color: #fff;
  border-bottom: 3px solid #1d3f73;
}

.setting-step {
  border-bottom: 1px solid #efefef;
}

.setting-step:last-child {
  border-bottom: 0;
}

.chk-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.setting-title {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 550;
  color: #000;
}

.btn-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 22px;
  margin: 14px 5px;
}

.btn-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.btn-switch input:checked + .checkbox-slider {
  background: #22b0af;
}

.btn-switch .checkbox-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 22px;
  width: 44px;
  border-radius: 16px;
  background-color: #edf1f5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.btn-switch .checkbox-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  top: calc(50% - 18px / 2);
  border-radius: 50%;
  background-color: white;
  filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.btn-switch input:checked + .checkbox-slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.password-des h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #000;
}

.password-des p {
  margin-bottom: 30px;
}

.card-top {
  display: flex;
  width: 100%;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
}

.card-event-img {
  width: 170px;
  height: 110px;
  margin-right: 20px;
}

.card-event-img img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
}

.card-event-dt h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #000;
}
.night-mode .card-event-dt h5 {
  color: white;
}
.invoice-id {
  color: #717171;
}

.card-bottom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.card-bottom-item {
  display: flex;
  width: 25%;
  align-items: center;
  padding: 20px;
}

.card-icon {
  font-size: 18px;
  color: #1d3f73;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 39px;
  background: #e8f7f7;
  border-radius: 50%;
}

.card-dt-text {
  margin-left: 15px;
}

.card-dt-text h6 {
  font-size: 13px;
  color: #717171;
}

.card-dt-text span {
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.card-dt-text a {
  color: #1d3f73;
  font-weight: 500;
  font-size: 14px;
}
.profile-download {
  color: #1d3f73 !important;
  font-weight: 500;
  font-size: 14px;
}
.profile-download:hover {
  color: #265399 !important;
}
.card-dt-text a:hover {
  color: #265399 !important;
}

.edit-btn {
  position: absolute;
  right: 0;
  top: 10px;
}

.about-details {
  padding: 30px;
}

.about-step {
  border-bottom: 1px solid #efefef;
  padding-bottom: 25px;
  padding-top: 25px;
}

.about-step:first-child {
  padding-top: 0;
}

.about-step:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.about-step h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 13px;
}
.night-mode .about-step h5 {
  color: white;
}
.about-step span {
  font-size: 18px;
  color: #717171;
  font-weight: 500;
  margin-bottom: 0;
}

.close-model-btn {
  box-sizing: content-box;
  font-size: 14px;
  width: 1.5em;
  height: 1.5em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.close-model-btn:hover {
  color: #1d3f73;
  opacity: 1;
}

.modal-title {
  font-size: 16px;
  font-weight: 500;
  font-family: inherit;
  color: #000;
}

.modal-header {
  border-bottom: 1px solid #efefef !important;
}

.modal-footer {
  border-top: 1px solid #efefef !important;
}

.modal-body {
  padding: 0 !important;
}

.model-content {
  padding: 0 30px 30px;
}

.model-content .form-control {
  background-color: #f9f9f9 !important;
}

.address-title {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
  padding-bottom: 13px;
  margin-top: 30px;
  color: #000;
}

.model-content .btn-light {
  min-height: 40px !important;
  display: block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #717171 !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #efefef !important;
  padding: 5px 20px !important;
  border-radius: 3px !important;
  transition: none !important;
}

.my-event-tabs {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
}

.event-link {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;
  width:  33.33%;
  display: block;
  border: none;
  border-bottom: 3px solid transparent;
  padding: 1rem;
  color: #bbb;
  background: transparent;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.event-link-org {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;
  width:  20%;
  display: block;
  border: none;
  border-bottom: 3px solid transparent;
  padding: 1rem;
  color: #bbb;
  background: transparent;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.my-event-tabs .event-link.active,
.my-event-tabs .show > .event-link {
  color: #1d3f73;
  background-color: #fff;
  border-bottom: 3px solid #1d3f73;
}

.my-event-tabs .event-link-org.active,
.my-event-tabs .show > .event-link-org {
  color: #1d3f73;
  background-color: #fff;
  border-bottom: 3px solid #1d3f73;
}
.my-event-tabs .event-link.active span,
.my-event-tabs .show > .event-link span {
  color: #1d3f73;
}

.my-event-tabs .event-link-org.active span,
.my-event-tabs .show > .event-link-org span {
  color: #1d3f73;
}

.event-link span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-top: 10px;
}
.event-link-org span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-top: 10px;
}


.my-event-tabs .event-link.active:hover {
  color: #1d3f73;
  background-color: #fff;
  border-bottom: 3px solid #1d3f73;
}
.my-event-tabs .event-link-org.active:hover {
  color: #1d3f73;
  background-color: #fff;
  border-bottom: 3px solid #1d3f73;
}
.event-count {
  background: #e8f7f7;
  display: inline-block !important;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  line-height: 36px;
}

.my-event-tabs .event-link.active .event-count,
.my-event-tabs .show > .event-link .event-count {
  color: #fff;
  background: #1d3f73;
}
.my-event-tabs .event-link-org.active .event-count,
.my-event-tabs .show > .event-link-org .event-count {
  color: #fff;
  background: #1d3f73;
}

.profile-event-content {
  padding: 15px 20px 20px;
}

.profile-event-content > .event-title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: block;
  line-height: 25px;
  min-height: 50px;
  margin-bottom: 33px;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.profile-event-content > .event-title:hover {
  color: #000 !important;
}

.profile-event-footer {
  padding: 0 20px 20px;
}

.event-btn-group {
  margin-top: 20px;
}

.esv-btn {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  height: 40px;
  min-width: 110px;
  border: 1px solid #efefef;
  border-radius: 3px;
  background: #fff;
  padding: 0 20px;
}
.transfer-btn {
  font-size: 15px;
  font-weight: 400;
  color: white;
  height: 40px;
  min-width: 110px;
  border: 1px solid #1d3f73;
  border-radius: 3px;
  background: #1d3f73;
  padding: 0 20px;
}
.profile-event-view {
  color: #000 !important;
}
.profile-event-view:hover {
  color: #000 !important;
}
.esv-btn:hover {
  color: #000;
  border: 1px solid #efefef;
  background: #e8f7f7;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.saved-btn {
  color: #fff !important;
  border: 1px solid #1d3f73 !important;
  background: #1d3f73 !important;
}

.saved-btn:hover {
  color: #fff !important;
  border: 1px solid #1d3f73 !important;
  background: #265399 !important;
}

.ff-tablist {
  border-bottom: 1px solid #efefef;
  margin-top: 27px;
}

.ff-tablist .nav-item .nav-link.active,
.ff-tablist .nav-item .show > .nav-link {
  color: #1d3f73 !important;
  border-color: #1d3f73;
  background-color: transparent;
}

.ff-tablist .nav-item .nav-link {
  font-size: 14px;
  font-weight: 400;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  padding: 5px 10px;
  margin-bottom: -1px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.min-height-430 {
  min-height: 430px;
}

.min-height-370 {
  min-height: 370px;
}

.min-height-180 {
  min-height: 180px;
}

.user-follow-card {
  display: flex;
  align-items: center;
  width: 100%;
}

.follow-card-left {
  display: flex;
  align-items: center;
}

.follow-avatar {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.follow-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.follow-name {
  margin-left: 15px;
}

.follow-name h5 {
  font-size: 16px;
  color: #000;
}

.follow-card-btn {
  margin-left: auto;
}

.follow-btn {
  color: #000;
  background: #fff !important;
  border-radius: 3px;
  border: 1px solid #bbb;
  padding: 3px 15px;
}

.follow-btn:hover {
  color: #000 !important;
  background: #e8f7f7 !important;
  border-radius: 3px;
  border: 1px solid #bbb;
}
.profile-lin-style {
  color: #000 !important;
}
.profile-lin-style:hover {
  color: #000 !important;
}
.fa-circle-check:before {
  content: "\f058" !important;
}
.fa-solid {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}
.verify-person {
  background-color: #1d3f73;
  color: white;
  border-radius: 20px;
  margin-top: -5px;
}
.night-mode .profile-lin-style {
  color: white !important;
  background-color: transparent !important;
}
.night-mode .bp-title h4 {
  color: white;
}
.night-mode .profile-night-form {
  color: white;
  background: transparent !important;
}
.night-mode .password-des h4 {
  color: white;
}
.modal-profile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-edit-popup {
  width: 52% !important;
  background-color: #fff;
  height: 98% !important;
  margin-top: 0.5% !important;
  margin-left: 22% !important ;
  display: block;
  border-radius: 7px;
}
.modal-dialog-profile {
  margin: 0% !important;
  max-width: 909px;
}
.profile-update-btn {
  height: 40px !important;
  line-height: 36px !important;
}
.modal-sty-text {
  font-weight: 600 !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.owner-title-sty {
  font-weight: 700;
  font-size: 15px;
  color: #000;
}
.owner-name {
  font-size: 17px;
  font-weight: 300;
}
.scroll-attend-event {
  height: 550px;
  overflow: auto;
}
.scroll-org-event {
  height: 667px;
}
.scroll-attend-event::-webkit-scrollbar {
  width: 10px !important;
}
.scroll-attend-event::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.scroll-attend-event::-webkit-scrollbar-thumb {
  background-color: #c5c5c7;
  border-radius: 10px;
  border: 3px solid #f5f7f9;
}
.tabBG {
  background: transparent !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgb(148, 138, 138);
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}
.css-lfjti6 {
  box-shadow: none !important;
}
.css-8r6hqs{
  box-shadow: none !important;
}
.resell-Btn {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #1d3f73;
  height: 37px;
  display: inline-block;
  line-height: 36px;
  padding: 0 30px;
  border-radius: 3px;
  border: 1px solid #1d3f73;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1d3f73 !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #1d3f73 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-weight: 600 !important;
  font-size: 0.99rem !important;
}
.currencyresellInput{
  width: 60px !important;
}
.priceresellInput{
  width: 200px !important;
}
.resell-Modal{
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 3%);
}
.download-btn-profile{
  background-color: transparent !important;
  border: none !important;
}
.invite-style{
  color: white !important;
  background-color: #1d3f73 !important;
}
.invite-style:hover{
  color: #000 !important;
}
.night-mode .event-link-org span{
  color: #bbbbbb;
}