.open-button {
    color: white;
    font-size:17px;
    max-width:300px;
    border-radius:20px;
    border: none;
    cursor: pointer;
    position: fixed;
    bottom: 23px;
    right: 28px;  
  }
  .supporticon{
    scale: 3;
    background-color: #1d3f73;
    border-radius:20px;

  }
  .supporticon:hover{
    background-color: white;
    border: 2px solid #1d3f73;
    color: #1d3f73;
    scale: 3.4;
  }
/* The popup chat - hidden by default */
.chat-popup {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
  }
  
  /* Add styles to the form container */
  .form-container {
    max-width: 350px;
    padding: 10px;
    background-color: white;
  }
  
  .form-container input {
    width: 100%;
    padding: 15px;
    margin: 5px 0 5px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 40px;
    font-size:15px;
  }

  /* Full-width textarea */
  .form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 5px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
    font-size:15px;
  }
  
  /* When the textarea gets focus, do something */
  .form-container textarea:focus {
    background-color: #ddd;
    outline: none;
  }
  
  /* Set a style for the submit/send button */
  .form-container .btn {
    background-color: #1d3f73;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom:10px;
    opacity: 0.8;
  }
  
  /* Add a red background color to the cancel button */
  .form-container .cancel {
    background-color: #e76161;
  }
  
  /* Add some hover effects to buttons */
  .form-container .btn:hover, .open-button:hover {
    opacity: 1;
  }
  