.search-form {
  margin-top: 33px;
  margin-bottom: 33px;
}
.location-icon{
  height: 14px;
  width: 14px;
}
.search-category .bootstrap-select .dropdown-menu li a span.text {
  margin-left: 7px !important;
}

.search-category
  .bootstrap-select
  .dropdown-toggle
  .filter-option-inner-inner
  i {
  margin-right: 7px !important;
}
.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
}
.form-control {
  font-size: 14px !important;
  color: #717171 !important;
  background-color: #fff !important;
  border: 1px solid #efefef !important;
  border-radius: 3px !important;
  transition: none !important;
  padding: 0 15px !important;
  min-height: 40px;
}

.form-control:focus {
  color: #717171 !important;
  background-color: #fff !important;
  border-color: #497bc5 !important;
  box-shadow: none !important;
}

.form-textarea {
  font-size: 14px !important;
  color: #717171 !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #efefef !important;
  border-radius: 3px !important;
  transition: none !important;
  padding: 0 15px !important;
  display: block;
  width: 100%;
  min-height: 90px;
  padding: 0.8rem 1rem !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-textarea:focus {
  color: #717171 !important;
  background-color: #f9f9f9 !important;
  border-color: #497bc5 !important;
  box-shadow: none !important;
}

.bs-searchbox .form-control {
  width: 100%;
  float: none;
  margin-bottom: 7px !important;
}

.search-form .btn-light {
  display: block !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #717171 !important;
  background-color: #fff !important;
  border: 1px solid #efefef !important;
  padding: 5px 20px !important;
  min-height: 50px !important;
  border-radius: 3px !important;
  transition: none !important;
}

.btn-light {
  display: block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #717171 !important;
  background-color: #fff !important;
  border: 1px solid #efefef !important;
  padding: 5px 20px !important;
  min-height: 40px !important;
  border-radius: 3px !important;
  transition: none !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100% !important;
}

.bootstrap-select .dropdown-menu.inner {
  padding: 0px !important;
  border: 0 !important;
  box-shadow: none !important;
  display: inline-block;
}

.main-form .bootstrap-select .dropdown-toggle:focus,
.main-form .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  outline: none !important;
  outline-offset: -2px;
  border-color: #a2ff7a !important;
  box-shadow: none !important;
}

.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
  color: #fff !important;
  border-radius: 0 !important;
  background-color: #1d3f73 !important;
}

.bootstrap-select.dropdown-item.active,
.bootstrap-select .dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #1d3f73 !important;
}

.bootstrap-select .dropdown-item {
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 10px 20px !important;
}

.bootstrap-select > .dropdown-toggle:after {
  margin-left: -10px !important;
}

.dropdown-menu {
  border-radius: 3px !important;
  background-color: #fff !important;
  border: 1px solid #f1f1f1 !important;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}
.hero-banner {
  background-image: url(../../assets/img/Tiq8-Banner.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0 80px;
}

.hero-banner-content {
  text-align: center;
}

.hero-banner-content h2 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 15px;
}

.hero-banner-content p {
  font-size: 18px;
  color: #000000;
  margin-bottom: 33px;
  padding: 0 30px;
  line-height: 32px;
}
.bootstrap-select > select.bs-select-hidden,
select.bs-select-hidden
 {
  display: none;
}
.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0;
}
.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0;
}
.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}
select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden
{
  display: none !important;
}
.bookmarkicon {
  text-align: center;
  margin-left: 10px;
  height: 26px;
  width: 25px;
  margin-top: -4px;
}
.bookmarkicon-fill{
  text-align: center;
  margin-left: 10px;
  height: 22px;
  width: 25px;
  margin-top: -4px;
}
.duration-price {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.bookmark-content::before {
  content: none !important;
}
.bookmark-content::after {
  content: none !important;
}
.form-group {
  background: #fff;
  /* padding: 9px; */
  border-radius: 3px;
}
.select-option-sty {
  width: 100%;
  padding: 16px;
  border: none;
  font-size: larger;
  outline: 0;
  background-color: #fff;
  color: #000;
}
.select-option-sty::selection {
  border: none;
  outline: 0;
}
select option:hover {
  margin-top: 10px;
  background-color: #1d3f73;
  /* width: 30px; */
  padding: 20px;
  line-height: 30px !important;
}
.select-option-sty select {
  width: 200% !important;
  border-radius: 0px;
}
.option-sty {
  padding: 7px;
  font-size: larger;
  width: 30px;
  height: 30px;
  margin-bottom: 30px;
}
.date-event-page{
  font-size: 12px !important;
}
.dot-size-allevent{
  margin: -5px -2px !important;
}
.event-css {
  margin-bottom: 0px !important;
}