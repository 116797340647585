.creatSeasonSeat {
  display: flex;
  padding: 5px;
  gap: 10px;
  position: relative;
}
.creatSeasonSeat label {
  display: block;
  position: relative;
  width: 100% !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1rem;
  padding: 2px;
  color: #000000 !important;
  background: #ffffff !important;
  border-radius: 2px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  transition-duration: 300ms;
  border: 3px solid #db6977 !important;
}
.creatSeasonSeat label:hover {
  cursor: pointer;
  box-shadow: 0px 0 7px 3px #db6977;
}
.creatSeasonSeat input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.creatSeasonSeat input[type="checkbox"]:disabled + label {
  background: #ddd !important;
  color: white !important;
  overflow: hidden !important;
  border: 3px solid #db6977 !important;
}

.creatSeasonSeat input[type="checkbox"]:disabled + label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.sesonEveCheckBox input[type="checkbox"]:checked {
  accent-color: #648dbb;
}

.night-mode .inputseasonalnight{
  background-color: #272b30 !important;
  color: #717171 !important;
}