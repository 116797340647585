.error-404-content h2 {
    font-size: 130px;
    font-weight: 800;
    letter-spacing: 10px;
}

.error-404-content h4 {
    font-size: 24px;
    margin-bottom: 21px;
}

.error-404-content p {
    font-size: 16px;
    padding: 0 50px;
    margin-bottom: 37px;
}
.homeicon{
    scale: 1.6;
    margin-top: -7px;
}