.breadcrumb-block {
  background-image: url(../../assets/img/Tiq8-Banner.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 21px 0;
}
.eventdetail-wrapper {
  margin-top: 70px;
}
.barren-breadcrumb .breadcrumb .breadcrumb-item a {
  color: #000 !important;
  font-size: 14px;
}

.barren-breadcrumb .breadcrumb .breadcrumb-item a:hover {
  color: #1d3f73 !important;
}

.barren-breadcrumb .breadcrumb .breadcrumb-item.active {
  color: #717171;
}

.barren-breadcrumb ol.breadcrumb {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
  width: 100%;
}

.barren-breadcrumb .breadcrumb .breadcrumb-item {
  display: inline;
}

.barren-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  float: none !important;
  color: #000 !important;
}

.event-top-dts {
  display: flex;
  width: 100%;
  position: relative;
}

.event-top-date {
  height: 100px;
  width: 100px;
  border: 1px solid #efefef;
  background: #fff;
  text-align: center;
  border-radius: 3px;
}

.event-month {
  display: block;
  background: #1d3f73;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 5px 0;
  text-transform: uppercase;
}

.event-date {
  font-size: 30px;
  padding: 8px 0;
  display: block;
  font-weight: 500;
  color: #000;
}

.event-top-dt {
  margin-left: 27px;
}

.event-type-name {
  font-size: 16px;
  font-weight: normal;
  color: #717171;
  position: relative;
}

.event-top-dt .event-top-info-status .event-type-name {
  padding-left: 11px;
  margin-right: 10px;
}

.event-top-dt .event-top-info-status .event-type-name:nth-child(1) {
  padding-left: 0;
}

.event-top-dt .event-top-info-status .event-type-name:nth-child(1) i {
  padding-right: 10px;
}

.event-main-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000;
}

.details-hr:after {
  content: "";
  width: 4px;
  height: 4px;
  background: #000;
  border-radius: 100%;
  display: inline-block;
  left: 0;
  top: 8px;
  position: absolute;
}

.event-img img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.main-event-dt {
  margin-top: 40px;
}

.main-event-content {
  margin-top: 37px;
}

.main-event-content h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #000;
}

.main-event-content p {
  margin-bottom: 27px;
}

.main-event-content p:last-child {
  margin-bottom: 0;
}

.booking-ends h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000;
  text-transform: uppercase;
  text-align: center;
}

.booking-ends {
  padding: 37px 20px 0;
}

.event-right-dt {
  margin-top: 40px;
}

.countdown {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 20px 0;
}

.countdown-item {
  width: 15%;
  padding: 10px 0px 0px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #1d3f73;
  margin-right: 5px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 3px rgb(0 0 0 / 5%);
  top: 23%;
  position: absolute;
}

.countdown-item:last-child {
  margin-right: 0;
}

.countdown-item span {
  display: block;
  font-size: 26px;
  font-weight: 400;
}

.event-dt-right-group {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0 20px;
  margin-bottom: 37px;
}

.event-dt-right-content {
  margin-left: 15px;
  width: 300px;
}
.calendar {
  width: 80px;
  height: 89px;
  background-color: #f4f4f4;
  border: 7px solid #000;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.calendar::before,
.calendar::after {
  content: "";
  width: 14px;
  height: 10px;
  background-color: #000;
  position: absolute;
  top: -11px;
  border-radius: 10px 10px 0 0;
}

.calendar::before {
  left: 10px;
}

.calendar::after {
  right: 10px;
}

.calendar .month {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  height: 45px;
  width: 80px;
  padding-block-start: 5px;
  padding-block-end: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
}

.calendar .day {
  color: #000;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 5px;
}
.event-dt-right-icon {
  font-size: 20px;
  color: #1d3f73;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 36px;
  background: #e8f7f7;
  border-radius: 50%;
}

.event-dt-right-content h4 {
  font-size: 14px;
  margin-bottom: 13px;
  font-weight: 400;
  color: #717171;
}

.event-dt-right-content h5 {
  font-size: 16px;
  margin-bottom: 0;
  color: #000;
  font-weight: 500;
  font-family: inherit;
}
.night-mode .event-dt-right-content h5 {
  color: white;
}
.event-dt-right-content a {
  color: #1d3f73;
  font-weight: 500;
  margin-top: 13px;
  display: inline-block;
}
.event-linkstye {
  color: #1d3f73 !important;
  font-weight: 500;
  margin-top: 13px;
  display: inline-block;
}
.event-dt-right-content a:hover {
  color: #265399 !important;
}

.booking-btn {
  padding: 0 20px 20px;
}

.add-to-calendar .dropdown-item {
  display: block;
  font-size: 14px;
  width: 100%;
  color: #000000;
  font-weight: 400;
  padding: 10px 20px;
  margin-top: 0;
}

.dropdown-item {
  display: block !important;
  font-size: 14px !important;
  width: 100% !important;
  color: #000000 !important;
  font-weight: 400 !important;
  padding: 10px 20px !important;
  margin-top: 0 !important;
}

.dropdown-item:hover {
  color: #1d3f73 !important;
  background-color: #e8f7f7 !important;
}

.share-save-btns {
  position: absolute;
  display: inline-flex;
  align-items: end;
  margin-block-end: 100px;
}

.sv-btn {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  height: 40px;
  width: 110px;
  /* width: max-content; */
  border: 1px solid #efefef;
  border-radius: 3px;
  background: #fff;
}
.org-copy-btn {
  width: 180px !important;
}
.sv-btn:hover {
  color: #000;
  border: 1px solid #efefef;
  background: #e8f7f7;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.more-events {
  border-top: 1px solid #efefef;
  padding-top: 23px;
}

.view-all-link {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #000 !important;
}
.night-mode .view-all-link {
  color: white !important;
}

.view-all-link:hover {
  color: #1d3f73 !important;
}

.main-card {
  position: relative;
  border: 1px solid #efefef;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
}
.bp-title h4 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  padding: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #efefef;
}
.fa-bookmark::before {
  content: "\f02e";
}
.fa-regular {
  font-family: "Font Awesome 6 Free";
  font-weight: 400;
}
.btn-hover:hover {
  color: #fff !important;
  background: #265399;
  border: 1px solid #265399;
}

.owl-carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.event-thumbnail {
  width: 100%;
  position: relative;
  padding: 0;
}

.thumbnail-img {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.event-thumbnail > .thumbnail-img::after {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.thumbnail-img:hover {
  outline: 0 !important;
  color: #000;
  transition: 0.3s;
  text-decoration: none;
}

.event-thumbnail a img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: transform 0.5s ease;
}

.event-thumbnail a:hover img {
  transform: scale(1.05);
}
.night-mode .eventdetail-nightmod {
  background-color: #111315 !important;
  color: #6f767e !important;
}
.night-mode .bp-title {
  color: white !important;
}
.event-content {
  padding: 15px 20px 20px;
  height: 170px;
}
.event-footer {
  background: rgb(106, 192, 69, 3%);
  padding: 10px 20px;
}
.duration-price-remaining {
  display: flex;
  width: 100%;
  align-items: center;
}
.duration-price {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.remaining {
  font-size: 14px;
  font-weight: 400;
  margin-left: auto;
  color: #717171;
}
.event-content > .event-title {
  font-size: 16px;
  font-weight: 600;
  color: #000 !important;
  display: block;
  line-height: 25px;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.night-mode .event-content > .event-title {
  color: white !important;
}
.event-timing {
  display: flex;
  align-items: center;
  color: #717171;
  font-size: 13px;
}
.publish-time {
  margin-left: auto;
}
/*evnet venue detail css */
.select-tickets-block h6 {
  font-size: 18px;
  font-family: inherit;
  color: #000;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 27px;
}
.select-tickets-block {
  padding: 0 20px 40px;
}
.select-ticket-action {
  display: flex;
  width: 100%;
  align-items: center;
}
.ticket-price {
  font-size: 18px;
  font-weight: 500;
  color: #717171;
}
.quantity {
  margin-left: auto;
}
.counter {
  width: 150px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter span {
  display: block;
  font-size: 24px;
  padding: 0;
  line-height: 26px;
  cursor: pointer;
  color: #000;
  user-select: none;
  background: #fff;
  border: 2px solid #bbb;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
}
.counter input {
  width: 40px;
  border: 0;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: transparent;
  color: #000;
  appearance: none;
  outline: 0;
}
.select-tickets-block p {
  margin-bottom: 20px;
  margin-top: 20px;
}
.xtotel-tickets-count {
  border-top: 1px solid #efefef;
  padding-top: 20px;
}
.x-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 13px;
}
.xtotel-tickets-count h4 {
  font-size: 22px;
  margin-bottom: 0;
  color: #000;
}
.night-mode .xtotel-tickets-count h4 {
  color: white;
}
.bookmark-icon {
  color: #fff;
  font-size: 20px;
  position: absolute;
  z-index: 101;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background-color: #1d3f73;
  display: block;
  height: 45px;
  width: 45px;
  line-height: 46px;
  border-radius: 30px;
  transition: all 0.4s;
}
.bookmark-icon:before {
  content: "\f02e";
  transform: scale(0.95);
}
.bookmark-icon:after {
  content: "\f02e";
  opacity: 0;
  transform: scale(0.3);
  font-weight: 900;
}
.bookmark-icon:before,
.bookmark-icon:after {
  transition: opacity 0.3s, transform 0.4s cubic-bezier(0, -0.41, 0.19, 2.5);
  font-family: "Font Awesome 6 Free";
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  top: 0px;
}
.night-mode .main-title h3 {
  color: white;
}

.eventdetail-imghight {
  height: 525px;
}
.TableSty {
  width: 100%;
  border: 1px solid #1d3f73;
}
.TableHeadSty {
  color: white;
  background-color: #1d3f73;
  padding: 7px;
  font-size: 17px;
  border: 1px solid white;
}
.TableBodySty {
  padding: 7px;
  font-size: 17px;
  font-weight: 500;
  background-color: white;
  border: 1px solid lightgray;
}
.tablescroll {
  height: 160px;
  overflow-y: scroll;
}
.css-1d3ml05-MuiPopper-root-MuiTooltip-popper {
  margin-left: 57px !important;
}
.countdownSty {
  color: white !important;
}
.Email-icon {
  height: 40px;
  width: 40px;
}

/* search */
.league-page-search {
  display: flex;
  width: 250px;
  height: 35px;
  background-color: #717171;
  position: absolute;
  margin-top: -10px;
  /* right: 100px; */
}
.league-page-search_league {
  top: 30px;
}
.league-page-search-input {
  width: 100%;
  height: 100%;
  padding: 2px 40px 2px 10px;
}
.league-page-search-icon {
  position: absolute;
  right: 0px;
  padding: 1px 6px;
  font-size: 22px;
  color: white;
  background-color: #1d3f73;
}
.league-page-search-breadcrumb {
  display: flex;
  position: absolute;
  top: 30px;
  /* left: 100px; */
}
.guest-btn {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  height: 50px;
  min-width: 110px;
  border: 1px solid #efefef;
  border-radius: 3px;
  background: #fff;
  padding: 0 20px;
}
.league-page-search-logo {
  position: absolute;
  left: 100px;
  top: 280px;
  width: 400px;
  height: 100px;
  border-radius: 10px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .countdown-item {
    width: 35%;
    top: 16%;
  }
}
@media (max-width: 768px) {
  .countdown-item {
    width: 35%;
    top: 16%;
  }
}
@media (max-width: 575.98px) {
  .countdown-item {
    width: 80%;
    top: 33em;
    scale: 0.7;
  }
  .countdown {
    padding: 0%;
  }
  .share-save-btns {
    scale: 0.8;
  }
}
@media (max-width: 600px) {
  .countdown-item {
    width: 80%;
    top: 16%;
    scale: 0.7;
  }
  .countdown {
    padding: 0%;
  }
}
