.reselticket-text{
    font-weight: 600;
    font-size: 23px;
    text-align: center;
}
.resell-card{
    width: 48%;
}
.resell-view-ticket{
    color: black !important;
}
.resell-view-ticket:hover{
    color: black !important;
}
.night-mode .resell-view-ticket{
    color: white !important;
}
.resell-img-style{
    width: 225px !important;
    height: 195px !important;
}