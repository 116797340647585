.app-form {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  min-height: 100vh;
}

.app-form-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #1e3f74 url(../../assets/img/Tiq8-Banner2025trw.png);
  width: 500px;
  padding: 50px;
}

.sidebar-sign-logo {
  width: 140px;
}

.sidebar-sign-logo img {
  max-width: 100%;
}

.app-form-content {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.app-form,
.app-form-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}

.app-form-content {
  padding: 50px 0;
  background: #fff;
}

.app-top-items {
  display: flex;
  align-items: center;
  width: 100%;
}

.app-top-right-link {
  margin-left: auto;
  margin-top: 5px;
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-register-link {
  margin-left: 5px;
  color: #1d3f73 !important;
  font-weight: 500;
}

.sidebar-register-link:hover {
  color: #265399 !important;
}

.signup-link {
  margin-left: 5px;
  color: #1d3f73 !important;
  font-weight: 500;
}

.signup-link:hover {
  color: #265399 !important;
}

.registration-title {
  margin-top: 35px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.pass-show-eye {
  color: #717171;
  font-weight: 600;
  position: absolute;
  background: transparent;
  right: 5px;
  top: 5px;
  min-height: 40px;
  padding: 0 15px;
  line-height: 40px;
  margin-bottom: 0;
  border-radius: 3px;
  cursor: pointer;
}

.pass-show-eye:hover {
  color: #1d3f73;
}

.field-password {
  position: relative;
}

.forgot-pass-link {
  position: absolute;
  top: 0;
  right: 0;
  color: #1d3f73 !important;
  font-weight: 500;
  font-size: 14px;
}

.forgot-pass-link:hover {
  color: #265399 !important;
}

.divider {
  position: relative;
  text-align: center;
  z-index: 1;
  margin: 30px 0;
}

.divider:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 1px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: rgba(112, 112, 112, 0.2);
}

.divider span {
  background: #fff;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.hr-line-dt {
  position: absolute;
  left: 0;
  top: -10px;
  padding: 0 20px 0 20px;
  background: #f9f9f9;
  color: #717171;
  font-size: 14px;
  font-weight: 500;
}

.new-sign-link {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 35px;
}

.copyright-footer {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 0 30px;
}

.sign_sidebar_text h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #fff;
}

.h_40 {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.h_50 {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}

.min-width {
  min-width: 130px;
  min-height: 40px;
  line-height: 39px;
  padding: 0 !important;
}

.sign-logo {
  display: none;
}

.sign-logo img {
  width: 100%;
  padding-top: 0;
  width: 97px;
  margin-left: 135%;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
  display: block;
}

.social-login-btn {
  display: block;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 50px;
  border: 1px solid #efefef;
  background: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
}

.social-login-btn:hover {
  background: #f5f5f5;
}

.registration .form-control {
  background-color: #f9f9f9 !important;
}

.agree-text {
  padding-top: 20px;
  line-height: 26px;
  font-size: 14px;
  color: #000;
}

.agree-text a {
  color: #1d3f73 !important;
}

.agree-text a:hover {
  color: #265399 !important;
  text-decoration: underline !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.main-btn {
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  background: #1d3f73;
  height: 50px;
  display: inline-block;
  line-height: 49px;
  padding: 0 30px;
  border-radius: 3px;
  border: 1px solid #1d3f73;
}
.troubletext {
  color: black;
}
.HeadlineText {
  color: #667085;
  font-size: large;
  font-weight: 500;
}
.regErr-p {
  color: red;
  text-align: center;
  margin-bottom: -10px;
  font-weight: 500;
  font-size: 20px;
}
@media (max-width: 575.98px) {
  .app-form-sidebar {
    display: none;
  }
}
