.removeBtnTeam {
  background-color: #e76161;
  color: white;
  border: 1px solid #e76161;
  padding: 8px 10px;
  border-radius: 4px;
  margin-inline-start: 10px;
  height: 35px;
  margin-top: 10px;
}
.removeBtnTeam:hover {
  color: #e76161;
  border: 1px solid #e76161;
  background-color: white;
  cursor: pointer;
}

/* Team Page css */
.new-main-tab {
  width: 170px;
  height: 36px;
  /* padding: 10px; */
  gap: 10px;
  border-radius: 50px;
  border: 0.5px 0px 0px 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  background: rgba(255, 255, 255, 1);
  border: 0.5px solid var(--Stroke---Medium, rgba(89, 89, 89, 1));
}
.new-main-tab:focus {
  background-color: rgba(29, 63, 115, 1);
  color: white;
}
.new-main-tab.selected {
  background-color: rgba(29, 63, 115, 1);
  color: white;
}
.calendar-tab {
  width: 109px !important;
}
.new-card-team {
  height: 102px;
  gap: 0px;
  background: rgba(255, 255, 255, 1);
}
.card-img-team {
  width: 201px;
  height: 102px;
  gap: 0px;
  border: 2px;
}
.buybtn-team {
  height: 41.14px;
  margin-top: 30px;
  padding: 8px 18px 8px 18px;
  gap: 10px;
  border-radius: 3px;
  background: rgba(29, 63, 115, 1);
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: center;
}
.form-control-BR {
  border: none !important;
  color: black !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-decoration-skip-ink: none;
}
.icon-team {
  scale: 2.2;
  margin-top: 13px;
}
.filter-team {
  width: 31%;
  border: 1px solid rgba(0, 0, 0, 1) !important;
  color: black !important;
}
.react-datepicker__calendar-icon {
  width: 2em;
  height: 1.5em;
  vertical-align: -0.125em;
}
.team-logo {
  width: 90px;
  height: 90px;
}
.season-details-team {
  margin: 0%;
}
@media (min-width: 320px) and (max-width: 600px) {
  .buybtn-team {
    margin-top: -7px;
  }
  .new-card-team {
    height: 297px;
  }
  .card-img-team {
    width: 100%;
    height: 130px;
  }
  .league-page-search-logo {
    left: 10px;
    width: 50%;
  }
  .league-page-search-breadcrumb {
    left: 0px;
  }
  .league-page-search-breadcrumb-legue{
    top: 70px;
    left: 10px;
  }
  .league-page-search {
    margin-top: 0px;
    left: 10px;
  }
  .league-page-search_league {
    top: 100px;
  }
  .filter-team {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .buybtn-team {
    margin-left: -40px;
  }
  .new-card-team {
    height: 196px;
    flex-wrap: nowrap !important;
  }
  .buybtn-team {
    width: 158px;
    height: 51.14px;
  }
  .league-page-search-logo {
    left: 35px;
  }
  .league-page-search {
    /* margin-top: 0px; */
    left: 537px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .buybtn-team {
    margin-left: 255px;
  }
  .responsive-team-card {
    width: 40%;
  }
  .league-page-search-logo {
    left: 50px;
    top: 220px;
  }
  .team-logo {
    width: 90px;
    height: 90px;
  }
  .league-page-search {
    /* margin-top: 0px; */
    left: 73.6%;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .league-page-search-logo {
    left: 100px;
    top: 280px;
  }
  .team-logo {
    width: 90px;
    height: 90px;
  }
  .league-page-search {
    /* margin-top: 0px; */
    left: 74.6%;
  }
}
@media (min-width: 769px) and (max-width: 1150px) {
  .responsive-team-card {
    width: 73%;
  }
  .new-card-team {
    width: 700px;
  }
  .league-page-search-logo {
    left: 60px;
    top: 240px;
  }
  .team-logo {
    width: 90px;
    height: 90px;
  }
  .league-page-search {
    /* margin-top: 0px; */
    left: 69%;
  }
}
@media (min-width: 1700px) and (max-width: 1750px) {
  .league-page-search-logo {
    left: 170px;
    top: 320px;
  }
  .team-logo {
    width: 90px;
    height: 90px;
  }
}

.fc .fc-col-header-cell-cushion {
  color: black !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}
.fc .fc-daygrid-day-number {
  font-size: 16px !important;
}
.calendar-event {
  background-color: #d9d9d9;
  width: 100%;
  padding: 7px;
}
.calendar-event-title {
  font-size: 16px;
  color: black;
}
.calendar-event-time {
  font-size: 12px;
  color: rgb(101, 100, 100);
}
.fc td {
}

.team-landing-main-img {
  width: 100%;
  object-fit: cover;
  height: 380px;
}

.location-icon-team {
  height: 18px;
  width: 18px;
}
